import HeaderLogin from "./HeaderLogin";
import HeaderEditor from "./HeaderEditor";
import { isLogin } from '../common/Utils';

const Header = ({component: Component, ...rest}) => {

    return (

        isLogin() ?
        <HeaderEditor/>
          : <HeaderLogin />
       
    );
  }
export default Header;
