import React,{useEffect,useState} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles';

import { Switch, Route, Redirect,useHistory } from "react-router";
import { removeIndicadorSession,removeUserSession, getDependencia ,getIndicador} from '../common/Utils';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {enviarDatosCapturados,editarRegistro} from '../common/Api';
import API from '../common/Api';
import TitleCard from '../dashboard/TitleCard';
import './Form.css'
const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      border: 0,
      backgroundColor: "#18202c",
    },
    drawerHeader: {
      // position: 'absolute',
      // top: theme.spacing(0),
      // right:theme.spacing(-3),
      zIndex: 100000,
      top: "-40px",
      display: "flex",
      alignItems: "center",
      // padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar, // necessary for content to be below app bar
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    Avatar: {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.light,
    },
    fab: {
      position: "absolute",
      top: theme.spacing(2),
      left: theme.spacing(-3),
      zIndex: 3000,
    },
    offset:theme.mixins.toolbar
}));








const Transparencia = (props) => {
  const indicador = getIndicador();
  const dependencia = getDependencia();
  if(dependencia!="Transparencia"){
    removeIndicadorSession();
  }
  var resultado="";
    const [indicador_56,set_indicador_56] = useState();
  const [total_indicador_56,set_total_indicador_56] = useState();
  const [porcentaje_indicador_56,set_porcentaje_indicador_56] = useState();
  const [indicador_57,set_indicador_57] = useState();
  const [total_indicador_57,set_total_indicador_57] = useState();
  const [porcentaje_indicador_57,set_porcentaje_indicador_57] = useState();
  const [indicador_58,set_indicador_58] = useState();
  const [total_indicador_58,set_total_indicador_58] = useState();
  const [porcentaje_indicador_58,set_porcentaje_indicador_58] = useState();
  const  [select_disabled, set_select_disabled] = useState(false);
  const  [field_disabled, set_field_disabled] = useState(false);
  const  [year,set_year] = useState();

  const onChange = (e) => {
    set_indicador_56(document.getElementById('indicador_56').value);
    set_total_indicador_56(document.getElementById('total_indicador_56').value);
    resultado = document.getElementById('indicador_56').value/
    document.getElementById('total_indicador_56').value
    if(!Number.isNaN(resultado)){
      set_porcentaje_indicador_56(((resultado.toFixed(2))*100).toString() + " %");
    }else{
      set_porcentaje_indicador_56("0%");
    }

    set_indicador_57(document.getElementById('indicador_57').value);
    set_total_indicador_57(document.getElementById('total_indicador_57').value);
    resultado = document.getElementById('indicador_57').value/
    document.getElementById('total_indicador_57').value
    if(!Number.isNaN(resultado)){
      set_porcentaje_indicador_57(((resultado.toFixed(2))*100).toString() + " %");
    }else{
      set_porcentaje_indicador_57("0%");
    }

    set_indicador_58(document.getElementById('indicador_58').value);
    set_total_indicador_58(document.getElementById('total_indicador_58').value);
    resultado = document.getElementById('indicador_58').value/
    document.getElementById('total_indicador_58').value
    if(!Number.isNaN(resultado)){
      set_porcentaje_indicador_58(((resultado.toFixed(2))*100).toString() + " %");
    }else{
      set_porcentaje_indicador_58("0%");
    }

    calcular();
  };



  useEffect(() =>{
    if(indicador){
      API.get("/api/indicador/"+indicador)
      .then(response => {


        set_indicador_56(response.data.datos.sancion.indicador_56.numerador);
        set_total_indicador_56(response.data.datos.sancion.indicador_56.total);
        set_porcentaje_indicador_56(response.data.datos.sancion.indicador_56.porcentaje);
        set_indicador_57(response.data.datos.sancion.indicador_57.numerador);
        set_total_indicador_57(response.data.datos.sancion.indicador_57.total);
        set_porcentaje_indicador_57(response.data.datos.sancion.indicador_57.porcentaje);
        set_indicador_58(response.data.datos.sancion.indicador_58.numerador);
        set_total_indicador_58(response.data.datos.sancion.indicador_58.total);
        set_porcentaje_indicador_58(response.data.datos.sancion.indicador_58.porcentaje);
        set_select_disabled(true);
        if(response.data.datos.year=="2020" || response.data.datos.year=="2019"){
          set_field_disabled(true)
        }else{
          set_field_disabled(false)
        }
        set_year(response.data.datos.year);

        }).catch(error => {
                return error;
        });
    }

  },[])



  const onSubmit = (event) => {

     event.preventDefault();

     const data = new FormData(event.target);
     var json_data = getData(data);
     var estado = sessionStorage["entidad_federativa"];
     console.log(json_data);
     if(!indicador){
      enviarDatosCapturados("Transparencia",json_data,estado,props);
     }else{
      editarRegistro(indicador,"Transparencia",estado,json_data,props);
     }

  };

    let nombreUsuario = localStorage.getItem("nombreUsuario");

    const { match } = props;
    let  history =  useHistory();
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openSubmenu = Boolean(anchorEl);


    const handleSalirSistema= ()=>{
      //BOrrar todo localstorage
      removeUserSession();
      history.push('/sign_in');
      // return <Redirect to="/login" />;

    }


    return (

      <div className="auth-wrapper">
      <div className="auth-inner">
      <form
            onSubmit={onSubmit}
             autoComplete="off"
             id="TransparenciaForm"
             className="form-inline"
           >
        <TitleCard title="Transparencia(Sanción)" />


              <Grid item xs={1}>
              <FormControl variant="filled" className={classes.formControl}>
                  <Select
                    native
                    required
                    value={year}

                    disabled={select_disabled === true ? true : false}
                    inputProps={{
                      name: 'year',
                      id: 'year',
                    }}
                  >
                    <option value={year}>{year}</option>
                    <option value="2021">2021</option>
                  <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </Select>
                  </FormControl>
              </Grid>



            <Grid container xs={12} spacing={1}>

              <span></span>


<Grid item xs={12}>

<div class="settings">
<label>Número total de sujetos obligados</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_56}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_56'
                    name="sancion.indicador_56.total"
                    onChange={onChange}
                    disabled={field_disabled === true ? true : false}
              />

             <label>Número de sujetos obligados sancionados</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_56}
             inputStyle ={{width: '50%'}}
             id='indicador_56'
             name="sancion.indicador_56.numerador"
             disabled={field_disabled === true ? true : false}
             onChange={onChange}
           />

           <label>Porcentaje de sujetos obligados sancionados por incumplimiento a obligaciones de transparencia</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_56'
             name="sancion.indicador_56.porcentaje"
             value={porcentaje_indicador_56}
             onChange={onChange}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Número de observaciones de portales realizadas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_57}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_57'
                    name="sancion.indicador_57.total"
                    onChange={onChange}
                    disabled={field_disabled === true ? true : false}
              />

             <label>Número de observaciones de portales solventadas</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_57}
             inputStyle ={{width: '50%'}}
             id='indicador_57'
             name="sancion.indicador_57.numerador"
             onChange={onChange}
             disabled={field_disabled === true ? true : false}
           />

           <label>Porcentaje de observaciones de portales atendidas</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_57'
             name="sancion.indicador_57.porcentaje"
             value={porcentaje_indicador_57}
             onChange={onChange}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de Quejas y Denuncias Recibidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_58}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_58'
                    name="sancion.indicador_58.total"
                    onChange={onChange}
                    disabled={field_disabled === true ? true : false}
              />

             <label>Total de Quejas y Denuncias Resueltas</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_58}
             inputStyle ={{width: '50%'}}
             id='indicador_58'
             name="sancion.indicador_58.numerador"
             disabled={field_disabled === true ? true : false}
             onChange={onChange}
           />

           <label>% Quejas y Denuncias Resueltas</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_58'
             name="sancion.indicador_58.porcentaje"
             value={porcentaje_indicador_58}
             onChange={onChange}
             inputProps={
               { readOnly: true, }
             }

           />

</div>

</Grid>
<Button
             type="submit"
             fullWidth
             variant="contained"
             color="primary"
             size="large"
             disabled={field_disabled === true ? true : false}
             // className={classes.submit}
           >
             Enviar
           </Button>
            </Grid>
          </form>
        </div>
        </div>

    );
}

function getData(data) {
  var data = {};

  var form = document.getElementById("TransparenciaForm");
  var allInputs = form.getElementsByTagName('input');
  for (let i = 0; i < allInputs.length; i++) {
    var str = allInputs[i].name;
    var val = allInputs[i].value;
    var strArr = str.split('.');
    generateObj(data, strArr, val);

    function generateObj(obj, arr, val) {

      if (arr.length === 1) {
        if(val==""){
          val="NR";
        }
        obj[arr[0]] = val
        return;
      }

      var restArr = arr.splice(1);
      if (!obj[arr[0]]) {
        obj[arr[0]] = {};
      }
      generateObj(obj[arr[0]], restArr, val);
    }

  };
  data["year"] = document.getElementById("year").value
  //alert(data);
  return data;
}

function calcular(){

  if(document.getElementById("indicador_56").value > 0
  && document.getElementById("total_indicador_56").value > 0){
  var resultado = document.getElementById("indicador_56").value/
                document.getElementById("total_indicador_56").value
  document.getElementById("porcentaje_indicador_56").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_57").value > 0
  && document.getElementById("total_indicador_57").value > 0){
  var resultado = document.getElementById("indicador_57").value/
                document.getElementById("total_indicador_57").value
  document.getElementById("porcentaje_indicador_57").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_58").value > 0
  && document.getElementById("total_indicador_58").value > 0){
  var resultado = document.getElementById("indicador_58").value/
                document.getElementById("total_indicador_58").value
  document.getElementById("porcentaje_indicador_58").value = ((resultado.toFixed(2))*100).toString() + " %";
  }

  }



export default Transparencia;