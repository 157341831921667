import React, { useState, useEffect } from 'react'
import {iniciarSesion} from '../common/Api';
import {NotificationManager} from 'react-notifications';
import { isLogin } from '../common/Utils';
import './Login.css'

  const Login = (props) => {



    const autenticado = isLogin();
    useEffect(()=>{

      if (autenticado) {
        setTimeout(() => {
          props.history.push("/register/lista_datos_capturados");
        }, 1000);
      }
  },[autenticado,props.history])

    //state para iniciar sesion
    const [usuario, guardarUsuario] = useState({
      email: "",
      password: "",
    });

    //Extarer de usuario
    const { email, password } = usuario;
    const onChange = (e) => {
      guardarUsuario({
          ...usuario,
          [e.target.name]: e.target.value,
      });
  };


    const onSubmit = (e) => {

      e.preventDefault();
      if (email.trim() === "" || password.trim() === "") {
        NotificationManager.error('Existen campos vacios', 'Error');
        return;
      }

      iniciarSesion(email, password,props);

    };



    return (
      <div className="auth-wrapper">
      <div className="auth-inner">
          <form onSubmit={onSubmit}>



          <div className="form-group">
              <label></label>
              <input type="email" className="form-control"  id="email"   name="email"
                              onChange={onChange} placeholder="Enter email" />
          </div>

          <div className="form-group">
              <label></label>
              <input type="password" className="form-control" id="password"   name="password"
                              onChange={onChange} placeholder="Enter password" />
          </div>


          <br/>
          <button type="submit"
                  size="large"
                  className="btn btn-primary btn-block">Ingresar
          </button>

          </form>
          </div>
          </div>
    );
  }

  export default Login;