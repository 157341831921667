import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.css';
import Grid from '@material-ui/core/Grid';
import { isLogin } from '../common/Utils';

class Footer extends React.Component
{
  render()
  {
    return (
      isLogin() ?
        <div class="phantomStyle">
        <footer class="footer">

                <div  class="centerthis" style={{fontSize:15+"px", textAlign:"  center"}}>
                  <span style={{color:"#FFFFFF", textAlign:"  center"}}>
                  © 2019, Iniciativa de Transparencia y Anticorrupción
                </span>
                </div>

                <div  class="centerthis" style={{fontSize:15+"px", textAlign:"  center"}}>
    <span style={{fontSize:15+"px"}}>
              <span style={{fontFamily:"wfont_5eb255_dac83d02473d4b4996319436f4cb4145,wf_dac83d02473d4b4996319436f,orig_neue_haas_grotesk_text_pro_55_roman"}}>
                <span style={{color:"#FFFFFF"}}>
                  Escuela de Gobierno y Transformación Pública
                </span>
              </span>
            </span>
    </div>
    <div  class="centerthis" >
    <h5 class="font_5" style={{fontSize:15+"px", textAlign:"  center"}}>
            <span style={{fontFamily:"wfont_5eb255_dac83d02473d4b4996319436f4cb4145,wf_dac83d02473d4b4996319436f,orig_neue_haas_grotesk_text_pro_55_roman"}}>
            <span style={{fontSize:15+"px"}}>
            <span style={{color:"#FFFFFF"}}>
                  EGobiernoyTP

                </span>

              </span>
            </span>
          </h5>
          <div  class="centerthis" style={{fontSize:15+"px", textAlign:"  center"}}>
          <a data-testid="linkElement" href="https://twitter.com/EGobiernoyTP" target="_blank" rel="noreferrer"  >
                  <img alt="Twitter Icono blanco" src="https://static.wixstatic.com/media/01ab6619093f45388d66736ec22e5885.png/v1/fill/w_42,h_42,al_c,q_85,usm_0.66_1.00_0.01/01ab6619093f45388d66736ec22e5885.webp" style={{width: 42+"px", height: 42+"px", objectFit: "cover"}}/>

              </a>
              <a data-testid="linkElement" href="https://www.facebook.com/EGobiernoyTP/" target="_blank" rel="noreferrer"  >
                   <img alt="Blanca Facebook Icono" src="https://static.wixstatic.com/media/23fd2a2be53141ed810f4d3dcdcd01fa.png/v1/fill/w_42,h_42,al_c,q_85,usm_0.66_1.00_0.01/23fd2a2be53141ed810f4d3dcdcd01fa.webp" style={{width: 42+"px", height: 42+"px", objectFit: "cover"}}/>

              </a>
              <a data-testid="linkElement" href="https://www.youtube.com/channel/UClxLkVGxBV6bJfLidDUxs-Q" target="_blank" rel="noreferrer"  >

                  <img alt="Blanco Icono de YouTube" src="https://static.wixstatic.com/media/203dcdc2ac8b48de89313f90d2a4cda1.png/v1/fill/w_42,h_42,al_c,q_85,usm_0.66_1.00_0.01/203dcdc2ac8b48de89313f90d2a4cda1.webp" style={{width: 42+"px", height: 42+"px", objectFit: "cover"}}/>

              </a>
              </div>
    </div>



      </footer>
      </div>:""
    )
  }
}
export default Footer;