import React from "react";
import Footer from "./site/Footer";
import Header from "./site/Header";
import Dashboard from "./dashboard/Dashboard";
import 'bootstrap/dist/css/bootstrap.min.css';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { isLogin } from './common/Utils';


//Rutas
import Login from './session/Login';
import PrivateRoute from "./private/PrivateRoute"

//Forms
import ListaIndicadores from './forms/ListaIndicadores';
import Transparencia from './forms/Transparencia';
import Finanzas from './forms/Finanzas';
import Poder_Judicial from './forms/Poder_Judicial';
import Tribunal from './forms/Tribunal';
import Fiscalia from './forms/Fiscalia';
import Auditoria from './forms/Auditoria';
import Funcion_Publica from './forms/Funcion_Publica';
import Porcentajes from './forms/Porcentajes';

import { BrowserRouter as Router,Route,Switch,Redirect } from 'react-router-dom';


const App = () => {

    return (

        <div className="App">

      <Router>
      <Header loggedIn={isLogin()}/>
      <br/>
      <br/>
      <br/>
          <div className="container">


       <NotificationContainer/>
       <Switch>
       <Route path='/sign_in' exact={true} component={Login}/>
       <Route path='/dashboard' exact={true} component={Dashboard}/>
       <Route exact path="/">
            {isLogin() ? <Redirect to="/register/lista_datos_capturados" /> :  <Redirect to='/sign_in' />}
        </Route>


       <PrivateRoute path='/register/lista_datos_capturados' exact={true} component={ListaIndicadores}/>
       <PrivateRoute path='/register/transparencia' exact={true} component={Transparencia}/>
       <PrivateRoute path='/register/finanzas' exact={true} component={Finanzas}/>
       <PrivateRoute path='/register/poder_judicial' exact={true} component={Poder_Judicial}/>
       <PrivateRoute path='/register/tribunal' exact={true} component={Tribunal}/>
       <PrivateRoute path='/register/auditoria' exact={true} component={Auditoria}/>
       <PrivateRoute path='/register/fiscalia' exact={true} component={Fiscalia}/>
       <PrivateRoute path='/register/funcion_publica' exact={true} component={Funcion_Publica}/>
       <PrivateRoute path='/register/porcentajes' exact={true} component={Porcentajes}/>
       </Switch>
        </div>
        <br/>
       <br/>
       <br/>
        <Footer loggedIn={isLogin()}/>

        </Router>
        </div>
    );
};
export default App;