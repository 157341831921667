import React, { useState } from 'react';
import API from '../common/Api';
import Grid from '@material-ui/core/Grid';
import MUIDataTable from "mui-datatables";
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';


import { Switch, Route, Redirect,useHistory } from "react-router";
import { removeIndicadorSession, getEmail,removeUserSession, setUserSession, setIndicadorSession,getDependencia ,getIndicador } from '../common/Utils';



import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {borrarIndicador} from '../common/Api';
import {NotificationContainer,NotificationManager} from 'react-notifications';
import './Form.css'

let arreglo_indicadores = []
let arreglo_dependencias = []
let arreglo_registro = []
let contador = 0;
const drawerWidth = 320;
var indicador = getIndicador();
var dependencia = getDependencia();

class ListaIndicadores extends React.Component {
    state = {
        data: [],
        open: true,

    };




    handleEliminar = (value, tableMeta, updateValue) => {



      var indicador_index = arreglo_indicadores[value];
      if (arreglo_registro[indicador_index] == "2020" ||
          arreglo_registro[indicador_index] == "2019"){
            NotificationManager.error("Este registro no se puede modificar", 'Error');
          }else{
      const r = window.confirm("¿Deseas eliminar este registro?"); if(r == true){
        //borrarIndicador(arreglo_indicadores[value]);
        //this.setState({ data: [] });
        API.delete("/api/indicador/"+indicador_index)
        .then(response => {
            var index = arreglo_indicadores.indexOf(indicador_index);
            NotificationManager.success('Registro Borrado', '');
            //console.log(this.state.data);
            const dataDelete = [...this.state.data];
            console.log(dataDelete);
            dataDelete.splice(index, 1);
            //console.log(dataDelete);
            arreglo_indicadores.splice(indicador_index, 1);
            indicador_index = ""
            this.setState({
              data: dataDelete
            });
        }).catch(error => {
                return error;
        });
      }
    }

    }

    handleEditar = (value) => {
      var indicador_index = arreglo_indicadores[value];
      var dependencia = arreglo_dependencias[value];
      setIndicadorSession(indicador_index,dependencia)
      if(dependencia=="Transparencia"){
        this.props.history.push('/register/transparencia');
      }
      if(dependencia=="PoderJudicial"){
        this.props.history.push('/register/poder_judicial');
      }
      if(dependencia=="Finanzas"){
        this.props.history.push('/register/finanzas');
      }
      if(dependencia=="Auditoria"){
        this.props.history.push('/register/auditoria');
      }
      if(dependencia=="Fiscalia"){
        this.props.history.push('/register/fiscalia');
      }
      if(dependencia=="Tribunal"){
        this.props.history.push('/register/tribunal');
      }
      if(dependencia=="FuncionPublica"){
        this.props.history.push('/register/funcion_publica');
      }

    }



    constructor(props) {
        super(props);

        this.ITEM_HEIGHT = 48;
        this.nombreUsuario = localStorage.getItem("nombreUsuario");
        removeIndicadorSession();

    }






    componentDidMount() {

      this.state = {
        data: []};
      this.state.open = true;
      let datos_capturados     = [];
        let nombreUsuario = localStorage.getItem("nombreUsuario");
        const handleSalirSistema= ()=>{
          //BOrrar todo localstorage
          removeUserSession();
          this.props.history.push('/sign_in');
          // return <Redirect to="/login" />;

        }
    API.get('/api/entidades_federativa/'+sessionStorage["entidad_federativa"]+'/indicadores').then(
        response => {

            for (let i = 0; i < response.data.length; i++) {
               arreglo_indicadores[i] = response.data[i].indicador;
               arreglo_dependencias[i] =   response.data[i].area.description;
               arreglo_registro[response.data[i].indicador] =   response.data[i].datos.year;
                datos_capturados.push([
                   response.data[i].area.description,
                   response.data[i].datos.year,
                   response.data[i].indicador,
                   response.data[i].indicador,
                ]);
            }
           // console.log(datos_capturados);
            this.setState({
                data: datos_capturados
              });
        }).catch(error => {
            console.log("error");
        });
    }


    columns = [
      {
        name: "area",
        label: "Autoridad",
      },
      {
        name: "year",
        label: "Año",
      },
      {
        name: "indicador",
        label: "Registro",
      },
 {
          name: "Delete",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (value, tableMeta, updateValue) => {
              return (
                <IconButton
                    aria-label="eliminar"
                    disabled={false}
                    onClick={() => this.handleEliminar(value, tableMeta, updateValue)}
                >
                <DeleteIcon />
              </IconButton>
              );
            }
          }
        },
        {
          name: "Edit",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex, rowIndex) => {
              return (
                <IconButton
                    aria-label="editar"
                    disabled={false}
                    // className={classes.root}
                    onClick={() => this.handleEditar(dataIndex)}
                >
            <EditIcon />
          </IconButton>
              );
            }
          }
        }


      ];

      options = {
        setTableProps: () => ({
          size: "small",
        }),
        filterType: "multiselect",
        selectableRows: 'none',//Oculta checkbox,
        download: false,
        print: false,
        viewColumns: false,
        textLabels: {
          selectedRows: {
            text: "filas seleccionadas",
            delete: "Eliminar",
            deleteAria: "Eliminar filas seleccionadas",
          },
          toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "Ver columnas",
            filterTable: "filtros",
          },
          pagination: {
            next: "Página siguiente ",
            previous: "Página anterior",
            rowsPerPage: "Filas por página:",
            displayRows: "de",
          },
          filter: {
            all: "Todos",
            title: "FILTROS",
            reset: "RESTABLECER",
          },
          body: {
            noMatch: "Lo sentimos, no se encontraron registros coincidentes",
            toolTip: "Ordenar",
            columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
          },
        },
        customToolbar: () => {

          return <IconButton aria-label="refresh"
          >
          <RefreshIcon />
        </IconButton>

        }
      };

    render() {
        const { classes } = this.props;

        return (


          <div className="auth-wrapper">
          <div className="wrapper flex-grow-1">

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <MUIDataTable
                  title={"Indicadores Capturados"}
                  data=  {this.state.data}
                  columns={this.columns}
                  options={this.options}
                />
              </Grid>
            </Grid>
            </div>
            </div>

        );
      }
}


export default ListaIndicadores;