import React from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import InfoUsuario from './InfoUsuario';
import {removeIndicadorSession } from '../common/Utils';

const Sidebar = () => {
  removeIndicadorSession();
  let nombre = sessionStorage.getItem("nombre");
  let email = sessionStorage.getItem("email");
  return (

    <div
      style={{ display: 'flex', width: '35vh', height: '100vh'}}
    >
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader>

            {email}<br/>
            {nombre}

        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/register/funcion_publica" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Función Pública</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/register/tribunal" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Tribunal</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/register/poder_judicial" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Poder Judicial</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/register/transparencia" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Transparencia</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/register/auditoria" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Auditoria</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/register/finanzas" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Finanzas</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/register/fiscalia" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Fiscalia</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/register/lista_datos_capturados" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Lista</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/register/porcentajes" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Porcentajes</CDBSidebarMenuItem>
            </NavLink>


          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            style={{
              padding: '20px 5px',
            }}
          >

          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
