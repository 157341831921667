import axios from 'axios';
import { setIndicadorSession, setUserSession,setPorcentajesEntidad } from '../common/Utils';
import {NotificationManager} from 'react-notifications';
import { useHistory } from 'react-router'
const API_URL = process.env.API_URL || 'https://observatorio.apistestedut83.net'
//const API_URL = process.env.API_URL || 'http://127.0.0.1:8080'

export default axios.create({
    baseURL: API_URL
});



export async function iniciarSesion(email,password,props){

    await axios.post(API_URL+"/api/auth",
                    {email: email, password: password })
                    .then(response => {
                        console.log(response.data.porcentajes);
                        setPorcentajesEntidad(response.data.porcentajes)
                        setUserSession(response.data.data.email,response.data.data.rol.description,response.data.data.nombre,response.data.data.entidad_federativa);

                        NotificationManager.success('Login Exitoso', '');
                        var delay = 3000;
                        setTimeout(function(){
                            props.history.go('/register/lista_datos_capturados');

                        },delay);

                    }).then((user)=>{
                        return "OK";
                    }).catch(error => {
                        NotificationManager.error('Login con Error', 'Error');
                            return error;
                      });
        }


export async function enviarDatosCapturados(area,data,estado,props){
    await axios.post(API_URL+"/api/indicadores",
    {"area":area, "entidad_federativa":estado ,"datos":data })
    .then(response => {
        props.history.push('/register/lista_datos_capturados');
    }).catch(error => {
            return error;
      });

}

export async function sendPostPorcentajes(estado,porcentaje_uno,porcentaje_dos,props){
    console.log("AWAIT");
    await axios.post(API_URL+"/api/entidades_federativa/porcentajes",
    {"porcentaje_uno":porcentaje_uno, "entidad_federativa":estado ,"porcentaje_dos":porcentaje_dos })
    .then(response => {
        NotificationManager.success('OK', '');
        props.history.push('/register/lista_datos_capturados');
    }).catch(error => {
        console.log(error);
        NotificationManager.error('ERROR', '');
            return error;
      });

}

export async function sendPutPorcentajes(estado,porcentaje_uno,porcentaje_dos,props){
    await axios.put(API_URL+"api/entidades_federativa/porcentajes",
    {"porcentaje_uno":porcentaje_uno, "entidad_federativa":estado ,"porcentaje_dos":porcentaje_dos })
    .then(response => {
        props.history.push('/register/lista_datos_capturados');
    }).catch(error => {
            return error;
      });

}

export async function editarRegistro(indicador,area,estado,data,props){
    //console.log(data);
    await axios.put(API_URL+"/api/indicador/"+indicador,
    {"area":area, "entidad_federativa":estado ,"datos":data })
    .then(response => {
        //setUserSession(response.data.email,response.data.rol.description);
        setIndicadorSession(null);
        props.history.push('/register/lista_datos_capturados');
    }).catch(error => {
            return error;
      });

}

export function borrarIndicador(indicador){

    axios.delete(API_URL+"/api/indicador/"+indicador)
    .then(response => {
        NotificationManager.success('Registro Borrado', '');
    }).catch(error => {
            return error;
    });

}

export function estadosConIndicadoresCapturados(){

    axios.post(API_URL+"/api/dashboard_estados")
    .then(response => {
        //NotificationManager.success('Registro Borrado', '');
        //console.log(response.data);
    }).catch(error => {
            return error;
    });

}
