import React,{useEffect,useState} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect,useHistory } from "react-router";
import { removeIndicadorSession, getDependencia ,getIndicador} from '../common/Utils';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {enviarDatosCapturados,editarRegistro} from '../common/Api';
import API from '../common/Api';
import TitleCard from '../dashboard/TitleCard';
import './Form.css'

const useStyles = makeStyles((theme) => ({

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

      offset:theme.mixins.toolbar

    }));


const FuncionPublica = (props) => {
    const classes = useStyles();
    const indicador = getIndicador();
    const dependencia = getDependencia();
    if(dependencia!="FuncionPublica"){
      removeIndicadorSession();
    }
    var resultado = "";
    var resultado="";
    const [indicador_1,set_indicador_1] = useState();
    const [total_indicador_1,set_total_indicador_1] = useState();
    const [porcentaje_indicador_1,set_porcentaje_indicador_1] = useState();
    const [indicador_2,set_indicador_2] = useState();
    const [total_indicador_2,set_total_indicador_2] = useState();
    const [porcentaje_indicador_2,set_porcentaje_indicador_2] = useState();
    const [indicador_3,set_indicador_3] = useState();
    const [indicador_4,set_indicador_4] = useState();
    const [indicador_5,set_indicador_5] = useState();
    const [indicador_6,set_indicador_6] = useState();
    const [total_indicador_6,set_total_indicador_6] = useState();
    const [porcentaje_indicador_6,set_porcentaje_indicador_6] = useState();
    const [indicador_7,set_indicador_7] = useState();
    const [total_indicador_7,set_total_indicador_7] = useState();
    const [porcentaje_indicador_7,set_porcentaje_indicador_7] = useState();
    const [indicador_8,set_indicador_8] = useState();
    const [total_indicador_8,set_total_indicador_8] = useState();
    const [porcentaje_indicador_8,set_porcentaje_indicador_8] = useState();
    const [indicador_9,set_indicador_9] = useState();
    const [total_indicador_9,set_total_indicador_9] = useState();
    const [porcentaje_indicador_9,set_porcentaje_indicador_9] = useState();
    const [indicador_10,set_indicador_10] = useState();
    const [total_indicador_10,set_total_indicador_10] = useState();
    const [porcentaje_indicador_10,set_porcentaje_indicador_10] = useState();
    const [indicador_11,set_indicador_11] = useState();
    const [total_indicador_11,set_total_indicador_11] = useState();
    const [porcentaje_indicador_11,set_porcentaje_indicador_11] = useState();
    const [indicador_12,set_indicador_12] = useState();
    const [total_indicador_12,set_total_indicador_12] = useState();
    const [porcentaje_indicador_12,set_porcentaje_indicador_12] = useState();
    const [indicador_13,set_indicador_13] = useState();
    const [total_indicador_13,set_total_indicador_13] = useState();
    const [porcentaje_indicador_13,set_porcentaje_indicador_13] = useState();
    const [indicador_14,set_indicador_14] = useState();
    const [total_indicador_14,set_total_indicador_14] = useState();
    const [porcentaje_indicador_14,set_porcentaje_indicador_14] = useState();
    const [indicador_15,set_indicador_15] = useState();
    const [total_indicador_15,set_total_indicador_15] = useState();
    const [porcentaje_indicador_15,set_porcentaje_indicador_15] = useState();
    const [indicador_16,set_indicador_16] = useState();
    const [indicador_17,set_indicador_17] = useState();
    const [indicador_18,set_indicador_18] = useState();
    const [indicador_19,set_indicador_19] = useState();
    const [indicador_20,set_indicador_20] = useState();
    const [indicador_21,set_indicador_21] = useState();

  useEffect(() =>{
    if(indicador){
      API.get("/api/indicador/"+indicador)
      .then(response => {

            set_select_disabled(true);
            if(response.data.datos.year=="2020" || response.data.datos.year=="2019"){
              set_field_disabled(true)
            }else{
              set_field_disabled(false)
            }
            set_year(response.data.datos.year);

            set_total_indicador_1(response.data.datos.investigacion.indicador_1.total);
            set_indicador_1(response.data.datos.investigacion.indicador_1.numerador);
            set_porcentaje_indicador_1(response.data.datos.investigacion.indicador_1.porcentaje);
            set_total_indicador_2(response.data.datos.investigacion.indicador_2.total);
            set_indicador_2(response.data.datos.investigacion.indicador_2.numerador);
            set_porcentaje_indicador_2(response.data.datos.investigacion.indicador_2.porcentaje);
            set_indicador_3(response.data.datos.investigacion.indicador_3);
            set_indicador_4(response.data.datos.investigacion.indicador_4);
            set_indicador_5(response.data.datos.investigacion.indicador_5);
            set_total_indicador_6(response.data.datos.sancion.indicador_6.total);
            set_indicador_6(response.data.datos.sancion.indicador_6.numerador);
            set_porcentaje_indicador_6(response.data.datos.sancion.indicador_6.porcentaje);
            set_total_indicador_7(response.data.datos.sancion.indicador_7.total);
            set_indicador_7(response.data.datos.sancion.indicador_7.numerador);
            set_porcentaje_indicador_7(response.data.datos.sancion.indicador_7.porcentaje);
            set_total_indicador_8(response.data.datos.sancion.indicador_8.total);
            set_indicador_8(response.data.datos.sancion.indicador_8.numerador);
            set_porcentaje_indicador_8(response.data.datos.sancion.indicador_8.porcentaje);
            set_total_indicador_9(response.data.datos.sancion.indicador_9.total);
            set_indicador_9(response.data.datos.sancion.indicador_9.numerador);
            set_porcentaje_indicador_9(response.data.datos.sancion.indicador_9.porcentaje);
            set_total_indicador_10(response.data.datos.sancion.indicador_10.total);
            set_indicador_10(response.data.datos.sancion.indicador_10.numerador);
            set_porcentaje_indicador_10(response.data.datos.sancion.indicador_10.porcentaje);
            set_total_indicador_11(response.data.datos.sancion.indicador_11.total);
            set_indicador_11(response.data.datos.sancion.indicador_11.numerador);
            set_porcentaje_indicador_11(response.data.datos.sancion.indicador_11.porcentaje);
            set_total_indicador_12(response.data.datos.sancion.indicador_12.total);
            set_indicador_12(response.data.datos.sancion.indicador_12.numerador);
            set_porcentaje_indicador_12(response.data.datos.sancion.indicador_12.porcentaje);
            set_total_indicador_13(response.data.datos.sancion.indicador_13.total);
            set_indicador_13(response.data.datos.sancion.indicador_13.numerador);
            set_porcentaje_indicador_13(response.data.datos.sancion.indicador_13.porcentaje);
            set_total_indicador_14(response.data.datos.sancion.indicador_14.total);
            set_indicador_14(response.data.datos.sancion.indicador_14.numerador);
            set_porcentaje_indicador_14(response.data.datos.sancion.indicador_14.porcentaje);
            set_total_indicador_15(response.data.datos.sancion.indicador_15.total);
            set_indicador_15(response.data.datos.sancion.indicador_15.numerador);
            set_porcentaje_indicador_15(response.data.datos.sancion.indicador_15.porcentaje);
            set_indicador_16(response.data.datos.sancion.indicador_16);
            set_indicador_17(response.data.datos.sancion.indicador_17);
            set_indicador_18(response.data.datos.sancion.indicador_18);
            set_indicador_19(response.data.datos.recuperacion.indicador_19);
            set_indicador_20(response.data.datos.recuperacion.indicador_20);
            set_indicador_21(response.data.datos.recuperacion.indicador_21);

        }).catch(error => {
                return error;
        });
    }

  },[])

    const onSubmit = (event) => {

        event.preventDefault();
         const data = new FormData(event.target);
         var json_data = getData(data);
         var estado = sessionStorage["entidad_federativa"];
         //json_data["entidad_federativa"] = sessionStorage["entidad_federativa"];
         console.log(json_data);
         if(!indicador){
          enviarDatosCapturados("FuncionPublica",json_data,estado,props);
         }else{
          editarRegistro(indicador,"FuncionPublica",estado,json_data,props);
         }

      };

      const  [select_disabled, set_select_disabled] = useState(false);
      const  [field_disabled, set_field_disabled] = useState(false);
      const  [year,set_year] = useState();
      const onChange = (e) => {
        set_indicador_1(document.getElementById('indicador_1').value);
         set_total_indicador_1(document.getElementById('total_indicador_1').value);
         resultado = document.getElementById('indicador_1').value/
         document.getElementById('total_indicador_1').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_1(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_1("0%");
         }
set_indicador_2(document.getElementById('indicador_2').value);
         set_total_indicador_2(document.getElementById('total_indicador_2').value);
         resultado = document.getElementById('indicador_2').value/
         document.getElementById('total_indicador_2').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_2(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_2("0%");
         }
        set_indicador_3(document.getElementById('indicador_3').value);

        set_indicador_4(document.getElementById('indicador_4').value);

        set_indicador_5(document.getElementById('indicador_5').value);

set_indicador_6(document.getElementById('indicador_6').value);
         set_total_indicador_6(document.getElementById('total_indicador_6').value);
         resultado = document.getElementById('indicador_6').value/
         document.getElementById('total_indicador_6').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_6(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_6("0%");
         }
set_indicador_7(document.getElementById('indicador_7').value);
         set_total_indicador_7(document.getElementById('total_indicador_7').value);
         resultado = document.getElementById('indicador_7').value/
         document.getElementById('total_indicador_7').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_7(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_7("0%");
         }
set_indicador_8(document.getElementById('indicador_8').value);
         set_total_indicador_8(document.getElementById('total_indicador_8').value);
         resultado = (document.getElementById('indicador_8').value/
         document.getElementById('total_indicador_8').value)*100
         if(!Number.isNaN(resultado)){
           //alert(parseFloat(resultado))
           set_porcentaje_indicador_8(((parseFloat(resultado).toFixed(2))).toString() + " %");
         }else{
           set_porcentaje_indicador_8("0%");
         }
set_indicador_9(document.getElementById('indicador_9').value);
         set_total_indicador_9(document.getElementById('total_indicador_9').value);
         resultado = document.getElementById('indicador_9').value/
         document.getElementById('total_indicador_9').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_9(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_9("0%");
         }
set_indicador_10(document.getElementById('indicador_10').value);
         set_total_indicador_10(document.getElementById('total_indicador_10').value);
         resultado = document.getElementById('indicador_10').value/
         document.getElementById('total_indicador_10').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_10(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_10("0%");
         }
set_indicador_11(document.getElementById('indicador_11').value);
         set_total_indicador_11(document.getElementById('total_indicador_11').value);
         resultado = (document.getElementById('indicador_11').value/
         document.getElementById('total_indicador_11').value)*100
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_11(((resultado.toFixed(2))).toString() + " %");
         }else{
           set_porcentaje_indicador_11("0%");
         }
set_indicador_12(document.getElementById('indicador_12').value);
         set_total_indicador_12(document.getElementById('total_indicador_12').value);
         resultado = document.getElementById('indicador_12').value/
         document.getElementById('total_indicador_12').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_12(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_12("0%");
         }
set_indicador_13(document.getElementById('indicador_13').value);
         set_total_indicador_13(document.getElementById('total_indicador_13').value);
         resultado = document.getElementById('indicador_13').value/
         document.getElementById('total_indicador_13').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_13(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_13("0%");
         }
set_indicador_14(document.getElementById('indicador_14').value);
         set_total_indicador_14(document.getElementById('total_indicador_14').value);
         resultado = document.getElementById('indicador_14').value/
         document.getElementById('total_indicador_14').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_14(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_14("0%");
         }
set_indicador_15(document.getElementById('indicador_15').value);
         set_total_indicador_15(document.getElementById('total_indicador_15').value);
         resultado = document.getElementById('indicador_15').value/
         document.getElementById('total_indicador_15').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_15(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_15("0%");
         }
set_indicador_16(document.getElementById('indicador_16').value);

set_indicador_17(document.getElementById('indicador_17').value);

set_indicador_18(document.getElementById('indicador_18').value);

set_indicador_19(document.getElementById('indicador_19').value);

          set_indicador_20(document.getElementById('indicador_20').value);

        set_indicador_21(document.getElementById('indicador_21').value);

        calcular();
      };

      const { match } = props;
    return (

      <div className="auth-wrapper">
      <div className="auth-inner">

          <form
            onSubmit={onSubmit}
             autoComplete="off"
             id="FuncionPublicaForm"
             className="form-inline"
           >
 <TitleCard title="Función Pública(Investigación)" />
          <Grid item xs={1}>
              <FormControl variant="filled" className={classes.formControl}>
                  <Select
                    native

                    value={year}

                    disabled={select_disabled === true ? true : false}
                    inputProps={{
                      name: 'year',
                      id: 'year',
                    }}
                  >
                     <option value={year}>{year}</option>
                    <option value="2021">2021</option>
                  <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </Select>
                  </FormControl>
              </Grid>

              <Grid container xs={12} spacing={1}>

              <Grid item xs={12}>
              <div class="settings">
              <label>
              Número de observaciones generadas
              </label>
              <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_1}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_1'
                    name="investigacion.indicador_1.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />
                          <label>
              Número de observaciones solventadas
              </label>

              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_1}
             inputStyle ={{width: '50%'}}
             id='indicador_1'
             name="investigacion.indicador_1.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
          <label>
 % De Observaciones solventadas de las auditorías que realizó Auditoría Superior a las cuentas públicas del Ejecutivo
              </label>

              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_1'
             name="investigacion.indicador_1.porcentaje"
             value={porcentaje_indicador_1}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />


              <label>
              Montos generados como resultado de procesos administrativos, sin importar si fueron recuperados o no
              </label>
              <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_2}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_2'
                    name="investigacion.indicador_2.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

               <label>
               Montos solventados
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_2}
             inputStyle ={{width: '50%'}}
             id='indicador_2'
             name="investigacion.indicador_2.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

             <label>
            % De solventación de Montos Observados en las cuentas públicas del ejecutivo, por la Auditoría Superior
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_2'
             name="investigacion.indicador_2.porcentaje"
             value={porcentaje_indicador_2}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />

<label>
            Número de procedimientos de responsabilidades iniciados
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_3}
             inputStyle ={{width: '50%'}}
             id='indicador_3'
             name="investigacion.indicador_3"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

                      <label>
                      Número de procedimientos de responsabilidades inactivos en los últimos 10 meses
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_4}
             inputStyle ={{width: '50%'}}
             id='indicador_4'
             name="investigacion.indicador_4"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>
           Número de denuncias penales ante la fiscalía anticorrupción por la posible comisión de actos de corrupción
              </label>
              <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={indicador_5}
                    inputStyle ={{width: '50%'}}
                    id='indicador_5'
                    name="investigacion.indicador_5"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />
           </div>

              </Grid>
              </Grid>



              <TitleCard title="Función Pública(Sanción)" />

<Grid container xs={12} spacing={1}>
<Grid item xs={12}>





<div class="settings">

<label>
Total de procedimientos administrativos
  </label>
<TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_6}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_6'
                    name="sancion.indicador_6.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />
  <label>
  Número de procedimientos administrativos resueltos
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_6}
             inputStyle ={{width: '50%'}}
             id='indicador_6'
             name="sancion.indicador_6.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
  <label>
  % De Procedimientos de responsabilidades administrativas por faltas no graves resueltos
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_6'
             name="sancion.indicador_6.porcentaje"
             value={porcentaje_indicador_6}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



  <label>
  Total de quejas y denuncias recibidas
  </label>

  <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_7}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_7'
                    name="sancion.indicador_7.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />
  <label>
  Número de quejas y denuncias que derivan en sanción administrativa y/o penal contra un servidor público
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_7}
             inputStyle ={{width: '50%'}}
             id='indicador_7'
             name="sancion.indicador_7.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
  <label>
  % De quejas y denuncias que derivan en sanciones administrativas a servidores públicos
  </label>
<TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_7'
             name="sancion.indicador_7.porcentaje"
             value={porcentaje_indicador_7}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />


  <label>
  Total de Quejas y Denuncias Recibidas
  </label>
  <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_8}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_8'
                    name="sancion.indicador_8.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />
  <label>
  Total de Quejas y Denuncias Resueltas
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_8}
             inputStyle ={{width: '50%'}}
             id='indicador_8'
             name="sancion.indicador_8.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
           <label>
           % Quejas y Denuncias Resueltas
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_8'
             name="sancion.indicador_8.porcentaje"
             value={porcentaje_indicador_8}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />
                     <label>
                     Total de Observaciones   </label>

                     <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_9}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_9'
                    name="sancion.indicador_9.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

<label>
Número de Procedimientos de Responsabilidad Administrativa   </label>

<TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_9}
             inputStyle ={{width: '50%'}}
             id='indicador_9'
             name="sancion.indicador_9.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />


<label>
% De inicio de procedimientos de responsabilidades derivados de las auditorías
</label>
<TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_9'
             name="sancion.indicador_9.porcentaje"
             value={porcentaje_indicador_9}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />


<label>
  Total de quejas y denuncias recibidas
  </label>

  <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_10}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_10'
                    name="sancion.indicador_10.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />
  <label>
  Número de quejas y denuncias que derivan en sanción administrativa y/o penal contra particulares
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_10}
             inputStyle ={{width: '50%'}}
             id='indicador_10'
             name="sancion.indicador_10.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
  <label>
  % De quejas y denuncias que derivan en sanciones administrativas a particulares
  </label>
<TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_10'
             name="sancion.indicador_10.porcentaje"
             value={porcentaje_indicador_10}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



<label>
Número total de servidores con proceso de responsabilidades
  </label>

  <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_11}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_11'
                    name="sancion.indicador_11.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />
  <label>
  Número de servidores públicos sancionados
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_11}
             inputStyle ={{width: '50%'}}
             id='indicador_11'
             name="sancion.indicador_11.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
  <label>
  % Servidores con sanciones administrativas  </label>
<TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_11'
             name="sancion.indicador_11.porcentaje"
             value={porcentaje_indicador_11}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />


                     <label>
                     total de procedimientos administrativos
  </label>
  <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_12}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_12'
                    name="sancion.indicador_12.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

<label>
  Servidores públicos suspendidos
  </label>
<TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_12}
             inputStyle ={{width: '50%'}}
             id='indicador_12'
             name="sancion.indicador_12.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

<label>
 %  Servidores públicos suspendidos
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_12'
             name="sancion.indicador_12.porcentaje"
             value={porcentaje_indicador_12}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />

<label>
  Total de procedimientos administrativos
  </label>
  <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_13}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_13'
                    name="sancion.indicador_13.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

<label>
 Servidores públicos destituidos
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_13}
             inputStyle ={{width: '50%'}}
             id='indicador_13'
             name="sancion.indicador_13.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />



          <label>
 % Servidores públicos destituidos
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_13'
             name="sancion.indicador_13.porcentaje"
             value={porcentaje_indicador_13}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />

<label>
  Total de procedimientos administrativos
  </label>
  <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_14}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_14'
                    name="sancion.indicador_14.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

<label>
 Servidores públicos sancionados económicamente
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_14}
             inputStyle ={{width: '50%'}}
             id='indicador_14'
             name="sancion.indicador_14.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />




          <label>
 % Servidores públicos sancionados económicamente
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_14'
             name="sancion.indicador_14.porcentaje"
             value={porcentaje_indicador_14}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />


<label>
  Total de procedimientos administrativos
  </label>
  <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_15}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_15'
                    name="sancion.indicador_15.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

          <label>
 Servidores públicos Inhabilitados temporalmente
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_15}
             inputStyle ={{width: '50%'}}
             id='indicador_15'
             name="sancion.indicador_15.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />



                     <label>
 % Servidores públicos Inhabilitados temporalmente
  </label>

  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_15'
             name="sancion.indicador_15.porcentaje"
             value={porcentaje_indicador_15}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />

<label>
        Número de funcionarios con sanción firme
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_16}
             inputStyle ={{width: '50%'}}
             id='indicador_16'
             name="sancion.indicador_16"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />


<label>
Número de Procedimientos de Responsabilidades que se encuentran en impugnación
  </label>
  <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_17}
             inputStyle ={{width: '50%'}}
             id='indicador_17'
             name="sancion.indicador_17"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

<label>
Número de impugnaciones (recursos, juicios, amparos) otorgadas en contra de las sanciones
  </label>
  <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={indicador_18}
                    inputStyle ={{width: '50%'}}
                    id='indicador_18'
                    name="sancion.indicador_18"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />
</div>
</Grid>


</Grid>



              <TitleCard title="Función Pública(Recuperación)" />
              <span>Ingresos a la hacienda pública </span><br/>
              <Grid container xs={12} spacing={1}>

              <div class="settings">
                    <label>
                   Por cobro de sanciones económicas o indemnizaciones por faltas administrativas o hechos de corrupción
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_19}
             inputStyle ={{width: '50%'}}
             id='indicador_19'
             name="recuperacion.indicador_19"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
           <label>
           Montos recuperados por decomiso de bienes producto de hechos ilícitos
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_20}
             inputStyle ={{width: '50%'}}
             id='indicador_20'
             name="recuperacion.indicador_20"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
                    <label>
                    Montos recuperados por extinción de dominio
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_21}
             inputStyle ={{width: '50%'}}
             id='indicador_21'
             name="recuperacion.indicador_21"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
           </div>
              <Button
             type="submit"
             fullWidth
             variant="contained"
             color="primary"
             size="large"
             disabled={field_disabled === true ? true : false}
             // className={classes.submit}
           >
             Enviar
           </Button>
            </Grid>
                   </form>
        </div>
        </div>
          );
}

function calcular(){
  if(document.getElementById("indicador_1").value > 0
  && document.getElementById("total_indicador_1").value > 0){
  var resultado = document.getElementById("indicador_1").value/
                document.getElementById("total_indicador_1").value
  document.getElementById("porcentaje_indicador_1").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_2").value > 0
  && document.getElementById("total_indicador_2").value > 0){
  var resultado = document.getElementById("indicador_2").value/
                document.getElementById("total_indicador_2").value
  document.getElementById("porcentaje_indicador_2").value = ((resultado.toFixed(2))*100).toString() + " %";
  }



if(document.getElementById("indicador_6").value > 0
  && document.getElementById("total_indicador_6").value > 0){
  var resultado = document.getElementById("indicador_6").value/
                document.getElementById("total_indicador_6").value
  document.getElementById("porcentaje_indicador_6").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_7").value > 0
  && document.getElementById("total_indicador_7").value > 0){
  var resultado = document.getElementById("indicador_7").value/
                document.getElementById("total_indicador_7").value
  document.getElementById("porcentaje_indicador_7").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_8").value > 0
  && document.getElementById("total_indicador_8").value > 0){
  var resultado = document.getElementById("indicador_8").value/
                document.getElementById("total_indicador_8").value
  document.getElementById("porcentaje_indicador_8").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_9").value > 0
  && document.getElementById("total_indicador_9").value > 0){
  var resultado = document.getElementById("indicador_9").value/
                document.getElementById("total_indicador_9").value
  document.getElementById("porcentaje_indicador_9").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_10").value > 0
  && document.getElementById("total_indicador_10").value > 0){
  var resultado = document.getElementById("indicador_10").value/
                document.getElementById("total_indicador_10").value
  document.getElementById("porcentaje_indicador_10").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_11").value > 0
  && document.getElementById("total_indicador_11").value > 0){
  var resultado = document.getElementById("indicador_11").value/
                document.getElementById("total_indicador_11").value
  document.getElementById("porcentaje_indicador_11").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_12").value > 0
  && document.getElementById("total_indicador_12").value > 0){
  var resultado = document.getElementById("indicador_12").value/
                document.getElementById("total_indicador_12").value
  document.getElementById("porcentaje_indicador_12").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_13").value > 0
  && document.getElementById("total_indicador_13").value > 0){
  var resultado = document.getElementById("indicador_13").value/
                document.getElementById("total_indicador_13").value
  document.getElementById("porcentaje_indicador_13").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_14").value > 0
  && document.getElementById("total_indicador_14").value > 0){
  var resultado = document.getElementById("indicador_14").value/
                document.getElementById("total_indicador_14").value
  document.getElementById("porcentaje_indicador_14").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_15").value > 0
  && document.getElementById("total_indicador_15").value > 0){
  var resultado = document.getElementById("indicador_15").value/
                document.getElementById("total_indicador_15").value
  document.getElementById("porcentaje_indicador_15").value = ((resultado.toFixed(2))*100).toString() + " %";
  }




}

function getData(data) {
  var data = {};

  var form = document.getElementById("FuncionPublicaForm");
  var allInputs = form.getElementsByTagName('input');
  for (let i = 0; i < allInputs.length; i++) {
    var str = allInputs[i].name;
    var val = allInputs[i].value;
    var strArr = str.split('.');
    generateObj(data, strArr, val);

    function generateObj(obj, arr, val) {

      if (arr.length === 1) {
        if(val==""){
          val="NR";
        }
        obj[arr[0]] = val
        return;
      }

      var restArr = arr.splice(1);
      if (!obj[arr[0]]) {
        obj[arr[0]] = {};
      }
      generateObj(obj[arr[0]], restArr, val);
    }

  };
  data["year"] = document.getElementById("year").value
  return data;
}

export default FuncionPublica;