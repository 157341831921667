import React,{useEffect,useState} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import { removeIndicadorSession, getDependencia ,getIndicador} from '../common/Utils';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {enviarDatosCapturados,editarRegistro} from '../common/Api';
import API from '../common/Api';
import TitleCard from '../dashboard/TitleCard';
import './Form.css'

const useStyles = makeStyles((theme) => ({

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

      offset:theme.mixins.toolbar

    }));


const Auditoria = (props) => {
    const classes = useStyles();
    const indicador = getIndicador();
    const dependencia = getDependencia();
    if(dependencia!="Auditoria"){
      removeIndicadorSession();
    }
    const onSubmit = (event) => {

        event.preventDefault();
         const data = new FormData(event.target);

         var json_data = getData(data);
         var estado = sessionStorage["entidad_federativa"];
         console.log(json_data);
         if(!indicador){
          enviarDatosCapturados("Auditoria",json_data,estado,props);
         }else{
          editarRegistro(indicador,"Auditoria",estado,json_data,props);
         }
      };
      var resultado="";
      const  [select_disabled, set_select_disabled] = useState(false);
      const  [field_disabled, set_field_disabled] = useState(false);
      const  [year,set_year] = useState();
      const [indicador_59,set_indicador_59] = useState();
      const [total_indicador_59,set_total_indicador_59] = useState();
      const [porcentaje_indicador_59,set_porcentaje_indicador_59] = useState();
      const [indicador_60,set_indicador_60] = useState();
      const [total_indicador_60,set_total_indicador_60] = useState();
      const [porcentaje_indicador_60,set_porcentaje_indicador_60] = useState();
      const [indicador_61,set_indicador_61] = useState();
      const [total_indicador_61,set_total_indicador_61] = useState();
      const [porcentaje_indicador_61,set_porcentaje_indicador_61] = useState();
      const [indicador_62,set_indicador_62] = useState();
      const [indicador_63,set_indicador_63] = useState();
      const [indicador_64,set_indicador_64] = useState();
      const [indicador_65,set_indicador_65] = useState();
      const [indicador_66,set_indicador_66] = useState();
      const [total_indicador_66,set_total_indicador_66] = useState();
      const [porcentaje_indicador_66,set_porcentaje_indicador_66] = useState();
      const [indicador_67,set_indicador_67] = useState();
      const [total_indicador_67,set_total_indicador_67] = useState();
      const [porcentaje_indicador_67,set_porcentaje_indicador_67] = useState();
      const [indicador_68,set_indicador_68] = useState();
      const [total_indicador_68,set_total_indicador_68] = useState();
      const [porcentaje_indicador_68,set_porcentaje_indicador_68] = useState();
      const [indicador_69,set_indicador_69] = useState();
      const [total_indicador_69,set_total_indicador_69] = useState();
      const [porcentaje_indicador_69,set_porcentaje_indicador_69] = useState();
      const [indicador_70,set_indicador_70] = useState();
      const [total_indicador_70,set_total_indicador_70] = useState();
      const [porcentaje_indicador_70,set_porcentaje_indicador_70] = useState();
      const [indicador_71,set_indicador_71] = useState();
      const [total_indicador_71,set_total_indicador_71] = useState();
      const [porcentaje_indicador_71,set_porcentaje_indicador_71] = useState();
      const [indicador_72,set_indicador_72] = useState();
      const [total_indicador_72,set_total_indicador_72] = useState();
      const [porcentaje_indicador_72,set_porcentaje_indicador_72] = useState();
      const [indicador_73,set_indicador_73] = useState();
      const [indicador_74,set_indicador_74] = useState();
      const [total_indicador_74,set_total_indicador_74] = useState();
      const [porcentaje_indicador_74,set_porcentaje_indicador_74] = useState();
      const [indicador_75,set_indicador_75] = useState();
      const [total_indicador_75,set_total_indicador_75] = useState();
      const [porcentaje_indicador_75,set_porcentaje_indicador_75] = useState();
      const [indicador_76,set_indicador_76] = useState();
      const [total_indicador_76,set_total_indicador_76] = useState();
      const [porcentaje_indicador_76,set_porcentaje_indicador_76] = useState();
      const [indicador_77,set_indicador_77] = useState();
      const [total_indicador_77,set_total_indicador_77] = useState();
      const [porcentaje_indicador_77,set_porcentaje_indicador_77] = useState();
      const [indicador_78,set_indicador_78] = useState();
      const [total_indicador_78,set_total_indicador_78] = useState();
      const [porcentaje_indicador_78,set_porcentaje_indicador_78] = useState();
      const [indicador_79,set_indicador_79] = useState();
      const [total_indicador_79,set_total_indicador_79] = useState();
      const [porcentaje_indicador_79,set_porcentaje_indicador_79] = useState();
      const [indicador_80,set_indicador_80] = useState();
      const [indicador_81,set_indicador_81] = useState();
      const [indicador_82,set_indicador_82] = useState();

      const onChange = (e) => {

        set_indicador_59(document.getElementById('indicador_59').value);
        set_total_indicador_59(document.getElementById('total_indicador_59').value);
        resultado = document.getElementById('indicador_59').value/
        document.getElementById('total_indicador_59').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_59(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_59("0%");
        }

        set_indicador_60(document.getElementById('indicador_60').value);
        set_total_indicador_60(document.getElementById('total_indicador_60').value);
        resultado = document.getElementById('indicador_60').value/
        document.getElementById('total_indicador_60').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_60(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_60("0%");
        }

        set_indicador_61(document.getElementById('indicador_61').value);
        set_total_indicador_61(document.getElementById('total_indicador_61').value);
        resultado = document.getElementById('indicador_61').value/
        document.getElementById('total_indicador_61').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_61(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_61("0%");
        }

        set_indicador_62(document.getElementById('indicador_62').value);


        set_indicador_63(document.getElementById('indicador_63').value);


        set_indicador_64(document.getElementById('indicador_64').value);


        set_indicador_65(document.getElementById('indicador_65').value);

        set_indicador_66(document.getElementById('indicador_66').value);
        set_total_indicador_66(document.getElementById('total_indicador_66').value);
        resultado = document.getElementById('indicador_66').value/
        document.getElementById('total_indicador_66').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_66(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_66("0%");
        }

        set_indicador_67(document.getElementById('indicador_67').value);
        set_total_indicador_67(document.getElementById('total_indicador_67').value);
        resultado = document.getElementById('indicador_67').value/
        document.getElementById('total_indicador_67').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_67(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_67("0%");
        }

        set_indicador_68(document.getElementById('indicador_68').value);
        set_total_indicador_68(document.getElementById('total_indicador_68').value);
        resultado = document.getElementById('indicador_68').value/
        document.getElementById('total_indicador_68').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_68(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_68("0%");
        }

        set_indicador_69(document.getElementById('indicador_69').value);
        set_total_indicador_69(document.getElementById('total_indicador_69').value);
        resultado = document.getElementById('indicador_69').value/
        document.getElementById('total_indicador_69').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_69(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_69("0%");
        }

        set_indicador_70(document.getElementById('indicador_70').value);
        set_total_indicador_70(document.getElementById('total_indicador_70').value);
        resultado = document.getElementById('indicador_70').value/
        document.getElementById('total_indicador_70').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_70(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_70("0%");
        }

        set_indicador_71(document.getElementById('indicador_71').value);
        set_total_indicador_71(document.getElementById('total_indicador_71').value);
        resultado = document.getElementById('indicador_71').value/
        document.getElementById('total_indicador_71').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_71(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_71("0%");
        }

        set_indicador_72(document.getElementById('indicador_72').value);
        set_total_indicador_72(document.getElementById('total_indicador_72').value);
        resultado = document.getElementById('indicador_72').value/
        document.getElementById('total_indicador_72').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_72(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_72("0%");
        }

        set_indicador_73(document.getElementById('indicador_73').value);


        set_indicador_74(document.getElementById('indicador_74').value);
        set_total_indicador_74(document.getElementById('total_indicador_74').value);
        resultado = document.getElementById('indicador_74').value/
        document.getElementById('total_indicador_74').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_74(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_74("0%");
        }

        set_indicador_75(document.getElementById('indicador_75').value);
        set_total_indicador_75(document.getElementById('total_indicador_75').value);
        resultado = document.getElementById('indicador_75').value/
        document.getElementById('total_indicador_75').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_75(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_75("0%");
        }

        set_indicador_76(document.getElementById('indicador_76').value);
        set_total_indicador_76(document.getElementById('total_indicador_76').value);
        resultado = document.getElementById('indicador_76').value/
        document.getElementById('total_indicador_76').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_76(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_76("0%");
        }

        set_indicador_77(document.getElementById('indicador_77').value);
        set_total_indicador_77(document.getElementById('total_indicador_77').value);
        resultado = document.getElementById('indicador_77').value/
        document.getElementById('total_indicador_77').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_77(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_77("0%");
        }

        set_indicador_78(document.getElementById('indicador_78').value);
        set_total_indicador_78(document.getElementById('total_indicador_78').value);
        resultado = document.getElementById('indicador_78').value/
        document.getElementById('total_indicador_78').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_78(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_78("0%");
        }

        set_indicador_79(document.getElementById('indicador_79').value);
        set_total_indicador_79(document.getElementById('total_indicador_79').value);
        resultado = document.getElementById('indicador_79').value/
        document.getElementById('total_indicador_79').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_79(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_79("0%");
        }

        set_indicador_80(document.getElementById('indicador_80').value);

        set_indicador_81(document.getElementById('indicador_81').value);

        set_indicador_82(document.getElementById('indicador_82').value);

         calcular();
      };

      useEffect(() =>{
        if(indicador){
          API.get("/api/indicador/"+indicador)
          .then(response => {
                set_select_disabled(true);
                if(response.data.datos.year=="2020" || response.data.datos.year=="2019"){
                  set_field_disabled(true)
                }else{
                  set_field_disabled(false)
                }
                set_year(response.data.datos.year);
                set_indicador_59(response.data.datos.investigacion.indicador_59.numerador);
set_total_indicador_59(response.data.datos.investigacion.indicador_59.total);

set_porcentaje_indicador_59(response.data.datos.investigacion.indicador_59.porcentaje);
set_indicador_60(response.data.datos.investigacion.indicador_60.numerador);
set_total_indicador_60(response.data.datos.investigacion.indicador_60.total);
set_porcentaje_indicador_60(response.data.datos.investigacion.indicador_60.porcentaje);
set_indicador_61(response.data.datos.investigacion.indicador_61.numerador);
set_total_indicador_61(response.data.datos.investigacion.indicador_61.total);
set_porcentaje_indicador_61(response.data.datos.investigacion.indicador_61.porcentaje);
set_indicador_62(response.data.datos.investigacion.indicador_62);
set_indicador_63(response.data.datos.investigacion.indicador_63);
set_indicador_64(response.data.datos.investigacion.indicador_64);
set_indicador_65(response.data.datos.investigacion.indicador_65);
set_indicador_66(response.data.datos.sancion.indicador_66.numerador);
set_total_indicador_66(response.data.datos.sancion.indicador_66.total);
set_porcentaje_indicador_66(response.data.datos.sancion.indicador_66.porcentaje);
set_indicador_67(response.data.datos.sancion.indicador_67.numerador);
set_total_indicador_67(response.data.datos.sancion.indicador_67.total);
set_porcentaje_indicador_67(response.data.datos.sancion.indicador_67.porcentaje);
set_indicador_68(response.data.datos.sancion.indicador_68.numerador);
set_total_indicador_68(response.data.datos.sancion.indicador_68.total);
set_porcentaje_indicador_68(response.data.datos.sancion.indicador_68.porcentaje);
set_indicador_69(response.data.datos.sancion.indicador_69.numerador);
set_total_indicador_69(response.data.datos.sancion.indicador_69.total);
set_porcentaje_indicador_69(response.data.datos.sancion.indicador_69.porcentaje);
set_indicador_70(response.data.datos.sancion.indicador_70.numerador);
set_total_indicador_70(response.data.datos.sancion.indicador_70.total);
set_porcentaje_indicador_70(response.data.datos.sancion.indicador_70.porcentaje);
set_indicador_71(response.data.datos.sancion.indicador_71.numerador);
set_total_indicador_71(response.data.datos.sancion.indicador_71.total);
set_porcentaje_indicador_71(response.data.datos.sancion.indicador_71.porcentaje);
set_indicador_72(response.data.datos.sancion.indicador_72.numerador);
set_total_indicador_72(response.data.datos.sancion.indicador_72.total);
set_porcentaje_indicador_72(response.data.datos.sancion.indicador_72.porcentaje);
set_indicador_73(response.data.datos.sancion.indicador_73);
set_indicador_74(response.data.datos.sancion.indicador_74.numerador);
set_total_indicador_74(response.data.datos.sancion.indicador_74.total);
set_porcentaje_indicador_74(response.data.datos.sancion.indicador_74.porcentaje);
set_indicador_75(response.data.datos.sancion.indicador_75.numerador);
set_total_indicador_75(response.data.datos.sancion.indicador_75.total);
set_porcentaje_indicador_75(response.data.datos.sancion.indicador_75.porcentaje);
set_indicador_76(response.data.datos.sancion.indicador_76.numerador);
set_total_indicador_76(response.data.datos.sancion.indicador_76.total);
set_porcentaje_indicador_76(response.data.datos.sancion.indicador_76.porcentaje);
set_indicador_77(response.data.datos.sancion.indicador_77.numerador);
set_total_indicador_77(response.data.datos.sancion.indicador_77.total);
set_porcentaje_indicador_77(response.data.datos.sancion.indicador_77.porcentaje);
set_indicador_78(response.data.datos.sancion.indicador_78.numerador);
set_total_indicador_78(response.data.datos.sancion.indicador_78.total);
set_porcentaje_indicador_78(response.data.datos.sancion.indicador_78.porcentaje);
set_indicador_79(response.data.datos.sancion.indicador_79.numerador);
set_total_indicador_79(response.data.datos.sancion.indicador_79.total);
set_porcentaje_indicador_79(response.data.datos.sancion.indicador_79.porcentaje);
set_indicador_80(response.data.datos.sancion.indicador_80);
set_indicador_81(response.data.datos.sancion.indicador_81);
set_indicador_82(response.data.datos.sancion.indicador_82);

        }).catch(error => {
          return error;
        });
    }

  },[]);

      const { match } = props;
    return (

      <div className="auth-wrapper">
      <div className="auth-inner">
          <TitleCard title="Auditoria(Investigación)" />
          <form
            onSubmit={onSubmit}
             autoComplete="off"
             id="AuditoriaForm"
             className={classes.form}
           >
               <Grid container xs={1} spacing={1}>
              <Grid item xs={1}>
              <FormControl variant="filled" className={classes.formControl}>
                  <Select
                    native

                    value={year}

                    disabled={select_disabled === true ? true : false}
                    inputProps={{
                      name: 'year',
                      id: 'year',
                    }}
                  >
                     <option value={year}>{year}</option>
                    <option value="2021">2021</option>
                  <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </Select>
                  </FormControl>
              </Grid>

            </Grid>

              <Grid item xs={12}>
              <div class="settings">

              <label>
              Total de Observaciones
              </label>
              <TextField
                variant="outlined"
                margin="normal"
                color='primary'


                inputStyle ={{width: '50%'}}
                id="total_indicador_59"
                name="investigacion.indicador_59.total"
                value={total_indicador_59}
                onChange={onChange} disabled={field_disabled === true ? true : false}
          />
              <label>
Número de Procedimientos de Responsabilidad Administrativa
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'


             inputStyle ={{width: '50%'}}
             value={indicador_59}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             id="indicador_59"
             name="investigacion.indicador_59.numerador"
             autoComplete="numerador_indicador_59"
           />



          <label>
          % De Promoción de Responsabilidades Administrativas Sancionatorias en relación al total de observaciones en informes de auditoría
              </label>
               <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             disabled
             inputStyle ={{width: '50%'}}
             id="porcentaje_indicador_59"
             name="investigacion.indicador_59.porcentaje"
             autoComplete="numerador_indicador_59"
             value=""
             value={porcentaje_indicador_59}
             onChange={onChange} disabled={field_disabled === true ? true : false}
                    inputProps={
                      { readOnly: true, }
                    }

           />

              <label>
              Número de observaciones generadas
              </label>
              <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'


                    inputStyle ={{width: '50%'}}
                    id="total_indicador_60"
                    name="investigacion.indicador_60.total"
                    autoComplete="total_indicador_60"

                    value={total_indicador_60}
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />
                          <label>
              Número de observaciones solventadas
              </label>
            <TextField
             variant="outlined"
             margin="normal"
             color='primary'


             inputStyle ={{width: '50%'}}

             id="indicador_60"
             name="investigacion.indicador_60.numerador"
             autoComplete="numerador_indicador_60"
             value={indicador_60}
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
            <label>
           % De Observaciones solventadas de las auditorias
              </label>
                      <TextField
             variant="outlined"
             margin="normal"
             color='primary'

             inputStyle ={{width: '50%'}}

             disabled
             id="porcentaje_indicador_60"
             label=""
             name="investigacion.indicador_60.porcentaje"
             autoComplete="porcentaje_indicador_60"
             value=""
             value={porcentaje_indicador_60}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />

<label>
              Montos generados como resultado de procesos administrativos, sin importar si fueron recuperados o no
              </label>
              <TextField
                  variant="outlined"
                  margin="normal"
                  color='primary'


                  inputStyle ={{width: '50%'}}

                  id="total_indicador_61"
                  name="investigacion.indicador_61.total"
                  autoComplete="total_indicador_61"

                  value={total_indicador_61}
                  onChange={onChange} disabled={field_disabled === true ? true : false}
                />

               <label>
               Montos solventados
              </label>
                <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'


                    inputStyle ={{width: '50%'}}
                    id="indicador_61"
                    name="investigacion.indicador_61.numerador"
                    autoComplete="numerador_indicador_61"

                    value={indicador_61}
                    onChange={onChange} disabled={field_disabled === true ? true : false}
                />

             <label>
            % De solventación de Montos Observados en las cuentas públicas del ejecutivo, por la Auditoría Superior
              </label>
                <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'

                    inputStyle ={{width: '50%'}}

                    disabled
                    id="porcentaje_indicador_61"
                    label=""
                    name="investigacion.indicador_61.porcentaje"
                    autoComplete="porcentaje_indicador_61"
                    value={porcentaje_indicador_61}
                    onChange={onChange} disabled={field_disabled === true ? true : false}
                    inputProps={
                      { readOnly: true, }
                    }

                  />

<label>
Número de procedimientos de responsabilidades administrativas iniciados</label>
<TextField
             variant="outlined"
             margin="normal"
             color='primary'


             fullWidth

             id="indicador_62"
             value={indicador_62}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             name="investigacion.indicador_62"
             autoComplete="indicador_62"
           />



<label>
Número de procedimientos de responsabilidades administrativas inactivos en los últimos 10 meses            </label>
<TextField
             variant="outlined"
             margin="normal"
             color='primary'


             fullWidth

             id="indicador_63"
             value={indicador_63}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             name="investigacion.indicador_63"
             autoComplete="indicador_63"
           />

<label>
Número de denuncias penales ante la fiscalía anticorrupción por posibles delitos por hechos de corrupción
              </label>
          <TextField
             variant="outlined"
             margin="normal"
             color='primary'


             fullWidth
             id="indicador_64"
             value={indicador_64}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             label=""
             name="investigacion.indicador_64"
             autoComplete="indicador_64"
           />

<label>
Número de procedimientos administrativos remitidos al Tribunal de Responsabilidades Administrativas
              </label>
          <TextField
             variant="outlined"
             margin="normal"
             color='primary'


             fullWidth
             id="indicador_65"
             value={indicador_65}
             onChange={onChange} disabled={field_disabled === true ? true : false}

             name="investigacion.indicador_65"
             autoComplete="indicador_65"
           />
           </div>




              </Grid>

              <TitleCard title="Auditoria(Sanción)" />
              <div class="settings">


              <label>Número de Entes Auditados</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_66}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_66'
                    name="sancion.indicador_66.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de entes sancionados por incumplimiento a las LCG o LDF</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_66}
             inputStyle ={{width: '50%'}}
             id='indicador_66'
             name="sancion.indicador_66.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Sanciones por incumplimiento a la Ley de Contabilidad Gubernamental (LCG) o Ley de disciplina financiera (LDF)</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_66'
             name="sancion.indicador_66.porcentaje"
             value={porcentaje_indicador_66}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />


              <label>Total de procedimientos administrativos iniciados </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_67}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_67'
                    name="sancion.indicador_67.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de procedimientos administrativos</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_67}
             inputStyle ={{width: '50%'}}
             id='indicador_67'
             name="sancion.indicador_67.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Procedimientos de responsabilidades administrativas por faltas graves de servidores públicos resueltos</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_67'
             name="sancion.indicador_67.porcentaje"
             value={porcentaje_indicador_67}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de procedimientos administrativos iniciados</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_68}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_68'
                    name="sancion.indicador_68.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de procedimientos administrativos resueltos </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_68}
             inputStyle ={{width: '50%'}}
             id='indicador_68'
             name="sancion.indicador_68.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Procedimientos de responsabilidades administrativas por faltas de particulares resueltos</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_68'
             name="sancion.indicador_68.porcentaje"
             value={porcentaje_indicador_68}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de quejas y denuncias recibidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_69}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_69'
                    name="sancion.indicador_69.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de quejas y denuncias que derivan en sanción administrativa contra un servidor público</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_69}
             inputStyle ={{width: '50%'}}
             id='indicador_69'
             name="sancion.indicador_69.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De quejas y denuncias que derivan en sanciones administrativas a servidores públicos</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_69'
             name="sancion.indicador_69.porcentaje"
             value={porcentaje_indicador_69}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de quejas y denuncias recibidas donde participó un particular </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_70}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_70'
                    name="sancion.indicador_70.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de quejas y denuncias que derivan en sanción administrativa contra un particular</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_70}
             inputStyle ={{width: '50%'}}
             id='indicador_70'
             name="sancion.indicador_70.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De quejas y denuncias que derivan en sanciones administrativas a particulares</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_70'
             name="sancion.indicador_70.porcentaje"
             value={porcentaje_indicador_70}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label> Total de Quejas y Denuncias Recibidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_71}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_71'
                    name="sancion.indicador_71.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label> Número Total de Quejas y Denuncias Resueltas </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_71}
             inputStyle ={{width: '50%'}}
             id='indicador_71'
             name="sancion.indicador_71.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% Quejas y Denuncias Resueltas</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_71'
             name="sancion.indicador_71.porcentaje"
             value={porcentaje_indicador_71}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de Observaciones </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_72}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_72'
                    name="sancion.indicador_72.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de Procedimiento de Responsabilidad Administrativa </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_72}
             inputStyle ={{width: '50%'}}
             id='indicador_72'
             name="sancion.indicador_72.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De inicio de procedimientos de responsabilidades derivados de las auditorías</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_72'
             name="sancion.indicador_72.porcentaje"
             value={porcentaje_indicador_72}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />





             <label>Número Servidores con sanciones administrativas y/o penales</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_73}
             inputStyle ={{width: '50%'}}
             id='indicador_73'
             name="sancion.indicador_73"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />




                <label>Total de procedimientos administrativos </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_74}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_74'
                    name="sancion.indicador_74.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Servidores públicos suspendidos dividido </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_74}
             inputStyle ={{width: '50%'}}
             id='indicador_74'
             name="sancion.indicador_74.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% Servidores públicos Suspendidos</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_74'
             name="sancion.indicador_74.porcentaje"
             value={porcentaje_indicador_74}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />


<label>Total de procedimientos administrativos </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_75}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_75'
                    name="sancion.indicador_75.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Servidores públicos destituidos</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_75}
             inputStyle ={{width: '50%'}}
             id='indicador_75'
             name="sancion.indicador_75.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% Servidores públicos destituidos</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_75'
             name="sancion.indicador_75.porcentaje"
             value={porcentaje_indicador_75}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



<label>Total de procedimientos administrativos </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_76}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_76'
                    name="sancion.indicador_76.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Servidores públicos sancionados económicamente</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_76}
             inputStyle ={{width: '50%'}}
             id='indicador_76'
             name="sancion.indicador_76.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% Servidores públicos sancionados económicamente</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_76'
             name="sancion.indicador_76.porcentaje"
             value={porcentaje_indicador_76}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Servidores públicos sancionados económicamente </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_77}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_77'
                    name="sancion.indicador_77.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Sanciones económicas cobradas</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_77}
             inputStyle ={{width: '50%'}}
             id='indicador_77'
             name="sancion.indicador_77.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% de sanciones económicas que han sido efectivamente cobradas</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_77'
             name="sancion.indicador_77.porcentaje"
             value={porcentaje_indicador_77}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de procedimientos administrativos </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_78}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_78'
                    name="sancion.indicador_78.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Servidores públicos sancionados con inhabilitación temporal </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_78}
             inputStyle ={{width: '50%'}}
             id='indicador_78'
             name="sancion.indicador_78.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% Servidores públicos sancionados con inhabilitación temporal</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_78'
             name="sancion.indicador_78.porcentaje"
             value={porcentaje_indicador_78}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de procedimientos administrativo</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_79}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_79'
                    name="sancion.indicador_79.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Particulares sancionados</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_79}
             inputStyle ={{width: '50%'}}
             id='indicador_79'
             name="sancion.indicador_79.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Particulares sancionados </label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_79'
             name="sancion.indicador_79.porcentaje"
             value={porcentaje_indicador_79}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />





             <label>Número de particulares y funcionarios con sanción firme</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_80}
             inputStyle ={{width: '50%'}}
             id='indicador_80'
             name="sancion.indicador_80"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

             <label>Número de Procedimientos de Responsabilidades que se encuentran en impugnación</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_81}
             inputStyle ={{width: '50%'}}
             id='indicador_81'
             name="sancion.indicador_81"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />


             <label>Número de impugnaciones (recursos, juicios, amparos) otorgadas en contra de las sanciones</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_82}
             inputStyle ={{width: '50%'}}
             id='indicador_82'
             name="sancion.indicador_82"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

              </div>
              <Button
             type="submit"
             fullWidth
             variant="contained"
             color="primary"
             size="large"
             disabled={field_disabled === true ? true : false}
             // className={classes.submit}
           >
             Enviar
           </Button>
                   </form>
        </div>
        </div>
          );
}

function calcular(){
  if(document.getElementById("indicador_59").value > 0
  && document.getElementById("total_indicador_59").value > 0){
  var resultado = document.getElementById("indicador_59").value/
                document.getElementById("total_indicador_59").value
  document.getElementById("porcentaje_indicador_59").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_60").value > 0
  && document.getElementById("total_indicador_60").value > 0){
  var resultado = document.getElementById("indicador_60").value/
                document.getElementById("total_indicador_60").value
  document.getElementById("porcentaje_indicador_60").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_61").value > 0
  && document.getElementById("total_indicador_61").value > 0){
  var resultado = document.getElementById("indicador_61").value/
                document.getElementById("total_indicador_61").value
  document.getElementById("porcentaje_indicador_61").value = ((resultado.toFixed(2))*100).toString() + " %";
  }



if(document.getElementById("indicador_66").value > 0
  && document.getElementById("total_indicador_66").value > 0){
  var resultado = document.getElementById("indicador_66").value/
                document.getElementById("total_indicador_66").value
  document.getElementById("porcentaje_indicador_66").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_67").value > 0
  && document.getElementById("total_indicador_67").value > 0){
  var resultado = document.getElementById("indicador_67").value/
                document.getElementById("total_indicador_67").value
  document.getElementById("porcentaje_indicador_67").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_68").value > 0
  && document.getElementById("total_indicador_68").value > 0){
  var resultado = document.getElementById("indicador_68").value/
                document.getElementById("total_indicador_68").value
  document.getElementById("porcentaje_indicador_68").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_69").value > 0
  && document.getElementById("total_indicador_69").value > 0){
  var resultado = document.getElementById("indicador_69").value/
                document.getElementById("total_indicador_69").value
  document.getElementById("porcentaje_indicador_69").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_70").value > 0
  && document.getElementById("total_indicador_70").value > 0){
  var resultado = document.getElementById("indicador_70").value/
                document.getElementById("total_indicador_70").value
  document.getElementById("porcentaje_indicador_70").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_71").value > 0
  && document.getElementById("total_indicador_71").value > 0){
  var resultado = document.getElementById("indicador_71").value/
                document.getElementById("total_indicador_71").value
  document.getElementById("porcentaje_indicador_71").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_72").value > 0
  && document.getElementById("total_indicador_72").value > 0){
  var resultado = document.getElementById("indicador_72").value/
                document.getElementById("total_indicador_72").value
  document.getElementById("porcentaje_indicador_72").value = ((resultado.toFixed(2))*100).toString() + " %";
  }




if(document.getElementById("indicador_74").value > 0
  && document.getElementById("total_indicador_74").value > 0){
  var resultado = document.getElementById("indicador_74").value/
                document.getElementById("total_indicador_74").value
  document.getElementById("porcentaje_indicador_74").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_75").value > 0
  && document.getElementById("total_indicador_75").value > 0){
  var resultado = document.getElementById("indicador_75").value/
                document.getElementById("total_indicador_75").value
  document.getElementById("porcentaje_indicador_75").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_76").value > 0
  && document.getElementById("total_indicador_76").value > 0){
  var resultado = document.getElementById("indicador_76").value/
                document.getElementById("total_indicador_76").value
  document.getElementById("porcentaje_indicador_76").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_77").value > 0
  && document.getElementById("total_indicador_77").value > 0){
  var resultado = document.getElementById("indicador_77").value/
                document.getElementById("total_indicador_77").value
  document.getElementById("porcentaje_indicador_77").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_78").value > 0
  && document.getElementById("total_indicador_78").value > 0){
  var resultado = document.getElementById("indicador_78").value/
                document.getElementById("total_indicador_78").value
  document.getElementById("porcentaje_indicador_78").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_79").value > 0
  && document.getElementById("total_indicador_79").value > 0){
  var resultado = document.getElementById("indicador_79").value/
                document.getElementById("total_indicador_79").value
  document.getElementById("porcentaje_indicador_79").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


}

function getData(data) {
  var data = {};

  var form = document.getElementById("AuditoriaForm");
  var allInputs = form.getElementsByTagName('input');
  for (let i = 0; i < allInputs.length; i++) {
    var str = allInputs[i].name;
    var val = allInputs[i].value;
    var strArr = str.split('.');
    generateObj(data, strArr, val);

    function generateObj(obj, arr, val) {
      //alert(val);
      if (arr.length === 1) {
        if(val==""){
          val="NR";
        }
        obj[arr[0]] = val
        return;
      }

      var restArr = arr.splice(1);
      if (!obj[arr[0]]) {
        obj[arr[0]] = {};
      }
      generateObj(obj[arr[0]], restArr, val);
    }

  };
  data["year"] = document.getElementById("year").value
  //alert(data);
  return data;
}

export default Auditoria;