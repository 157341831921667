import React,{useEffect,useState} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect,useHistory } from "react-router";
import { removeIndicadorSession, getDependencia ,getIndicador} from '../common/Utils';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {enviarDatosCapturados,editarRegistro} from '../common/Api';
import API from '../common/Api';
import TitleCard from '../dashboard/TitleCard';
import './Form.css'

const useStyles = makeStyles((theme) => ({

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

      offset:theme.mixins.toolbar

    }));


const Fiscalia = (props) => {
    const classes = useStyles();
    const indicador = getIndicador();
    const dependencia = getDependencia();
    if(dependencia!="Fiscalia"){
      removeIndicadorSession();
    }
    const onSubmit = (event) => {

        event.preventDefault();
         const data = new FormData(event.target);
         var json_data = getData(data);
         var estado = sessionStorage["entidad_federativa"];  console.log(json_data);
         enviarDatosCapturados("Fiscalia",json_data,estado,props);
      };
      var resultado=""
      const  [select_disabled, set_select_disabled] = useState(false);
      const  [field_disabled, set_field_disabled] = useState(false);
      const  [year,set_year] = useState();
      const [indicador_88,set_indicador_88] = useState();
      const [total_indicador_88,set_total_indicador_88] = useState();
      const [porcentaje_indicador_88,set_porcentaje_indicador_88] = useState();
      const [indicador_89,set_indicador_89] = useState();
      const [total_indicador_89,set_total_indicador_89] = useState();
      const [porcentaje_indicador_89,set_porcentaje_indicador_89] = useState();
      const [indicador_90,set_indicador_90] = useState();
      const [total_indicador_90,set_total_indicador_90] = useState();
      const [porcentaje_indicador_90,set_porcentaje_indicador_90] = useState();
      const [indicador_91,set_indicador_91] = useState();
      const [total_indicador_91,set_total_indicador_91] = useState();
      const [porcentaje_indicador_91,set_porcentaje_indicador_91] = useState();
      const [indicador_92,set_indicador_92] = useState();
      const [total_indicador_92,set_total_indicador_92] = useState();
      const [porcentaje_indicador_92,set_porcentaje_indicador_92] = useState();
      const [indicador_93,set_indicador_93] = useState();
      const [total_indicador_93,set_total_indicador_93] = useState();
      const [porcentaje_indicador_93,set_porcentaje_indicador_93] = useState();
      const [indicador_94,set_indicador_94] = useState();
      const [total_indicador_94,set_total_indicador_94] = useState();
      const [porcentaje_indicador_94,set_porcentaje_indicador_94] = useState();
      const [indicador_95,set_indicador_95] = useState();
      const [total_indicador_95,set_total_indicador_95] = useState();
      const [porcentaje_indicador_95,set_porcentaje_indicador_95] = useState();
      const [indicador_96,set_indicador_96] = useState();
      const [total_indicador_96,set_total_indicador_96] = useState();
      const [porcentaje_indicador_96,set_porcentaje_indicador_96] = useState();
      const [indicador_97,set_indicador_97] = useState();
      const [total_indicador_97,set_total_indicador_97] = useState();
      const [porcentaje_indicador_97,set_porcentaje_indicador_97] = useState();
      const [indicador_98,set_indicador_98] = useState();
      const [total_indicador_98,set_total_indicador_98] = useState();
      const [porcentaje_indicador_98,set_porcentaje_indicador_98] = useState();
      const [indicador_99,set_indicador_99] = useState();
      const [total_indicador_99,set_total_indicador_99] = useState();
      const [porcentaje_indicador_99,set_porcentaje_indicador_99] = useState();
      const [indicador_100,set_indicador_100] = useState();
      const [total_indicador_100,set_total_indicador_100] = useState();
      const [porcentaje_indicador_100,set_porcentaje_indicador_100] = useState();
      const [indicador_101,set_indicador_101] = useState();
      const [total_indicador_101,set_total_indicador_101] = useState();
      const [porcentaje_indicador_101,set_porcentaje_indicador_101] = useState();
      const [indicador_102,set_indicador_102] = useState();
      const [total_indicador_102,set_total_indicador_102] = useState();
      const [porcentaje_indicador_102,set_porcentaje_indicador_102] = useState();
      const [indicador_103,set_indicador_103] = useState();
      const [total_indicador_103,set_total_indicador_103] = useState();
      const [porcentaje_indicador_103,set_porcentaje_indicador_103] = useState();
      const [indicador_104,set_indicador_104] = useState();
      const [total_indicador_104,set_total_indicador_104] = useState();
      const [porcentaje_indicador_104,set_porcentaje_indicador_104] = useState();
      const [indicador_105,set_indicador_105] = useState();
      const [total_indicador_105,set_total_indicador_105] = useState();
      const [porcentaje_indicador_105,set_porcentaje_indicador_105] = useState();
      const [indicador_106,set_indicador_106] = useState();
      const [indicador_107,set_indicador_107] = useState();
      const [indicador_108,set_indicador_108] = useState();

      const onChange = (e) => {
        set_indicador_88(document.getElementById('indicador_88').value);
        set_total_indicador_88(document.getElementById('total_indicador_88').value);
        resultado = document.getElementById('indicador_88').value/
        document.getElementById('total_indicador_88').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_88(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_88("0%");
        }

        set_indicador_89(document.getElementById('indicador_89').value);


        set_indicador_90(document.getElementById('indicador_90').value);

        set_indicador_91(document.getElementById('indicador_91').value);
        set_total_indicador_91(document.getElementById('total_indicador_91').value);
        resultado = (document.getElementById('indicador_91').value/
        document.getElementById('total_indicador_91').value)*100
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_91(((resultado.toFixed(2))).toString() + " %");
        }else{
          set_porcentaje_indicador_91("0%");
        }

        set_indicador_92(document.getElementById('indicador_92').value);
        set_total_indicador_92(document.getElementById('total_indicador_92').value);
        resultado = document.getElementById('indicador_92').value/
        document.getElementById('total_indicador_92').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_92(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_92("0%");
        }

        set_indicador_93(document.getElementById('indicador_93').value);
        set_total_indicador_93(document.getElementById('total_indicador_93').value);
        resultado = document.getElementById('indicador_93').value/
        document.getElementById('total_indicador_93').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_93(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_93("0%");
        }

        set_indicador_94(document.getElementById('indicador_94').value);
        set_total_indicador_94(document.getElementById('total_indicador_94').value);
        resultado = (document.getElementById('indicador_94').value/
        document.getElementById('total_indicador_94').value)*100
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_94(((resultado.toFixed(2))).toString() + " %");
        }else{
          set_porcentaje_indicador_94("0%");
        }

        set_indicador_95(document.getElementById('indicador_95').value);


        set_indicador_96(document.getElementById('indicador_96').value);
        set_total_indicador_96(document.getElementById('total_indicador_96').value);
        resultado = document.getElementById('indicador_96').value/
        document.getElementById('total_indicador_96').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_96(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_96("0%");
        }

        set_indicador_97(document.getElementById('indicador_97').value);
        set_total_indicador_97(document.getElementById('total_indicador_97').value);
        resultado = document.getElementById('indicador_97').value/
        document.getElementById('total_indicador_97').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_97(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_97("0%");
        }

        set_indicador_98(document.getElementById('indicador_98').value);
        set_total_indicador_98(document.getElementById('total_indicador_98').value);
        resultado = document.getElementById('indicador_98').value/
        document.getElementById('total_indicador_98').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_98(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_98("0%");
        }

        set_indicador_99(document.getElementById('indicador_99').value);
        set_total_indicador_99(document.getElementById('total_indicador_99').value);
        resultado = document.getElementById('indicador_99').value/
        document.getElementById('total_indicador_99').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_99(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_99("0%");
        }

        set_indicador_100(document.getElementById('indicador_100').value);
        set_total_indicador_100(document.getElementById('total_indicador_100').value);
        resultado = document.getElementById('indicador_100').value/
        document.getElementById('total_indicador_100').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_100(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_100("0%");
        }

        set_indicador_101(document.getElementById('indicador_101').value);
        set_total_indicador_101(document.getElementById('total_indicador_101').value);
        resultado = document.getElementById('indicador_101').value/
        document.getElementById('total_indicador_101').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_101(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_101("0%");
        }

        set_indicador_102(document.getElementById('indicador_102').value);
        set_total_indicador_102(document.getElementById('total_indicador_102').value);
        resultado = document.getElementById('indicador_102').value/
        document.getElementById('total_indicador_102').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_102(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_102("0%");
        }

        set_indicador_103(document.getElementById('indicador_103').value);
        set_total_indicador_103(document.getElementById('total_indicador_103').value);
        resultado = document.getElementById('indicador_103').value/
        document.getElementById('total_indicador_103').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_103(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_103("0%");
        }

        set_indicador_104(document.getElementById('indicador_104').value);
        set_total_indicador_104(document.getElementById('total_indicador_104').value);
        resultado = document.getElementById('indicador_104').value/
        document.getElementById('total_indicador_104').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_104(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_104("0%");
        }

        set_indicador_105(document.getElementById('indicador_105').value);
        set_total_indicador_105(document.getElementById('total_indicador_105').value);
        resultado = document.getElementById('indicador_105').value/
        document.getElementById('total_indicador_105').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_105(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_105("0%");
        }

        set_indicador_106(document.getElementById('indicador_106').value);
        set_indicador_107(document.getElementById('indicador_107').value);
        set_indicador_108(document.getElementById('indicador_108').value);



        calcular();
      };

      useEffect(() =>{
        if(indicador){
          API.get("/api/indicador/"+indicador)
          .then(response => {
                set_select_disabled(true);
                if(response.data.datos.year=="2020" || response.data.datos.year=="2019"){
                  set_field_disabled(true)
                }else{
                  set_field_disabled(false)
                }
                set_year(response.data.datos.year);
                set_indicador_88(response.data.datos.investigacion.indicador_88.numerador);
set_total_indicador_88(response.data.datos.investigacion.indicador_88.total);
set_porcentaje_indicador_88(response.data.datos.investigacion.indicador_88.porcentaje);
set_indicador_89(response.data.datos.investigacion.indicador_89);
set_indicador_90(response.data.datos.investigacion.indicador_90);
set_indicador_91(response.data.datos.investigacion.indicador_91.numerador);
set_total_indicador_91(response.data.datos.investigacion.indicador_91.total);
set_porcentaje_indicador_91(response.data.datos.investigacion.indicador_91.porcentaje);
set_indicador_92(response.data.datos.investigacion.indicador_92.numerador);
set_total_indicador_92(response.data.datos.investigacion.indicador_92.total);
set_porcentaje_indicador_92(response.data.datos.investigacion.indicador_92.porcentaje);
set_indicador_93(response.data.datos.investigacion.indicador_93.numerador);
set_total_indicador_93(response.data.datos.investigacion.indicador_93.total);
set_porcentaje_indicador_93(response.data.datos.investigacion.indicador_93.porcentaje);
set_indicador_94(response.data.datos.investigacion.indicador_94.numerador);
set_total_indicador_94(response.data.datos.investigacion.indicador_94.total);
set_porcentaje_indicador_94(response.data.datos.investigacion.indicador_94.porcentaje);
set_indicador_95(response.data.datos.investigacion.indicador_95);
set_indicador_96(response.data.datos.sancion.indicador_96.numerador);
set_total_indicador_96(response.data.datos.sancion.indicador_96.total);
set_porcentaje_indicador_96(response.data.datos.sancion.indicador_96.porcentaje);
set_indicador_97(response.data.datos.sancion.indicador_97.numerador);
set_total_indicador_97(response.data.datos.sancion.indicador_97.total);
set_porcentaje_indicador_97(response.data.datos.sancion.indicador_97.porcentaje);
set_indicador_98(response.data.datos.sancion.indicador_98.numerador);
set_total_indicador_98(response.data.datos.sancion.indicador_98.total);
set_porcentaje_indicador_98(response.data.datos.sancion.indicador_98.porcentaje);
set_indicador_99(response.data.datos.sancion.indicador_99.numerador);
set_total_indicador_99(response.data.datos.sancion.indicador_99.total);
set_porcentaje_indicador_99(response.data.datos.sancion.indicador_99.porcentaje);
set_indicador_100(response.data.datos.sancion.indicador_100.numerador);
set_total_indicador_100(response.data.datos.sancion.indicador_100.total);
set_porcentaje_indicador_100(response.data.datos.sancion.indicador_100.porcentaje);
set_indicador_101(response.data.datos.sancion.indicador_101.numerador);
set_total_indicador_101(response.data.datos.sancion.indicador_101.total);
set_porcentaje_indicador_101(response.data.datos.sancion.indicador_101.porcentaje);
set_indicador_102(response.data.datos.sancion.indicador_102.numerador);
set_total_indicador_102(response.data.datos.sancion.indicador_102.total);
set_porcentaje_indicador_102(response.data.datos.sancion.indicador_102.porcentaje);
set_indicador_103(response.data.datos.sancion.indicador_103.numerador);
set_total_indicador_103(response.data.datos.sancion.indicador_103.total);
set_porcentaje_indicador_103(response.data.datos.sancion.indicador_103.porcentaje);
set_indicador_104(response.data.datos.sancion.indicador_104.numerador);
set_total_indicador_104(response.data.datos.sancion.indicador_104.total);
set_porcentaje_indicador_104(response.data.datos.sancion.indicador_104.porcentaje);
set_indicador_105(response.data.datos.sancion.indicador_105.numerador);
set_total_indicador_105(response.data.datos.sancion.indicador_105.total);
set_porcentaje_indicador_105(response.data.datos.sancion.indicador_105.porcentaje);
set_indicador_106(response.data.datos.recuperacion.indicador_106);
set_indicador_107(response.data.datos.recuperacion.indicador_107);
set_indicador_108(response.data.datos.recuperacion.indicador_108);


              }).catch(error => {
                    return error;
            });
        }

      },[]);

      const { match } = props;
    return (

      <div className="auth-wrapper">
      <div className="auth-inner">
          <TitleCard title="Fiscalía(Investigación)" />
          <form
            onSubmit={onSubmit}
             autoComplete="off"
             id="FiscaliaForm"
             className={classes.form}
           >
               <Grid container xs={1} spacing={1}>
              <Grid item xs={1}>
              <FormControl variant="filled" className={classes.formControl}>
                  <Select
                    native

                    value={year}

                    disabled={select_disabled === true ? true : false}
                    inputProps={{
                      name: 'year',
                      id: 'year',
                    }}
                  >
                     <option value={year}>{year}</option>
                    <option value="2021">2021</option>
                  <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </Select>
                  </FormControl>
              </Grid>

            </Grid>
            <Grid container xs={12} spacing={1}>
              <Grid item xs={12}>
              <div class="settings">
              <label>Carpetas de investigación iniciadas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_88}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_88'
                    name="investigacion.indicador_88.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Carpetas Judicializadas </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_88}
             inputStyle ={{width: '50%'}}
             id='indicador_88'
             name="investigacion.indicador_88.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% de Carpetas Judicializadas</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_88'
             name="investigacion.indicador_88.porcentaje"
             value={porcentaje_indicador_88}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />




             <label>Número de denuncias penales ante la fiscalía anticorrupción</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_89}
             inputStyle ={{width: '50%'}}
             id='indicador_89'
             name="investigacion.indicador_89"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />






             <label>Número de carpetas de investigación integradas</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_90}
             inputStyle ={{width: '50%'}}
             id='indicador_90'
             name="investigacion.indicador_90"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />




                <label>Número de denuncias recibidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_91}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_91'
                    name="investigacion.indicador_91.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de no ejercicios de acción penal </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_91}
             inputStyle ={{width: '50%'}}
             id='indicador_91'
             name="investigacion.indicador_91.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% de no ejercicios de la acción penal</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_91'
             name="investigacion.indicador_91.porcentaje"
             value={porcentaje_indicador_91}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Número de denuncias recibidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_92}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_92'
                    name="investigacion.indicador_92.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número criterios de oportunidad</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_92}
             inputStyle ={{width: '50%'}}
             id='indicador_92'
             name="investigacion.indicador_92.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% de criterios de oportunidad</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_92'
             name="investigacion.indicador_92.porcentaje"
             value={porcentaje_indicador_92}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Número de denuncias recibidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_93}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_93'
                    name="investigacion.indicador_93.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de expedientes en reserva o archivo temporal</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_93}
             inputStyle ={{width: '50%'}}
             id='indicador_93'
             name="investigacion.indicador_93.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% de expedientes en reserva o archivo temporal</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_93'
             name="investigacion.indicador_93.porcentaje"
             value={porcentaje_indicador_93}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Número de denuncias recibidas </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_94}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_94'
                    name="investigacion.indicador_94.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de abstenciones</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_94}
             inputStyle ={{width: '50%'}}
             id='indicador_94'
             name="investigacion.indicador_94.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% de abstenciones de la acusación</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_94'
             name="investigacion.indicador_94.porcentaje"
             value={porcentaje_indicador_94}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />




             <label>Número de formulaciones de imputación</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_95}
             inputStyle ={{width: '50%'}}
             id='indicador_95'
             name="investigacion.indicador_95"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />



              </div>
              </Grid>


              <TitleCard title="Fiscalía(Sanción)" />
              <Grid container xs={12} spacing={1}>
              <Grid item xs={12}>

              <div class="settings">
              <label>Número de denuncias recibidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_96}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_96'
                    name="sancion.indicador_96.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de denuncias que derivan en sanción penal</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_96}
             inputStyle ={{width: '50%'}}
             id='indicador_96'
             name="sancion.indicador_96.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De denuncias que derivan en sanciones penales a servidores públicos</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_96'
             name="sancion.indicador_96.porcentaje"
             value={porcentaje_indicador_96}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de sentencias emitidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_97}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_97'
                    name="sancion.indicador_97.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de sentencias condenatorias a servidores públicos por hechos de corrupción</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_97}
             inputStyle ={{width: '50%'}}
             id='indicador_97'
             name="sancion.indicador_97.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Sentencias Condenatorias a Servidores Públicos por delitos relacionados a hechos de corrupción</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_97'
             name="sancion.indicador_97.porcentaje"
             value={porcentaje_indicador_97}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



<label>Total de sentencias emitidas</label>

               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_98}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_98'
                    name="sancion.indicador_98.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />
<label>Número de sentencias condenatorias a particulares por hechos de corrupción</label>

             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_98}
             inputStyle ={{width: '50%'}}
             id='indicador_98'
             name="sancion.indicador_98.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

<label>% De Sentencias Condenatorias a Particulares por delitos relacionados a hechos de corrupción</label>

           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_98'
             name="sancion.indicador_98.porcentaje"
             value={porcentaje_indicador_98}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />


<label>Total de Quejas y Denuncias Recibidas </label>

               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_99}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_99'
                    name="sancion.indicador_99.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

<label>Total de Quejas y Denuncias Resueltas</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_99}
             inputStyle ={{width: '50%'}}
             id='indicador_99'
             name="sancion.indicador_99.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

<label>% Quejas y Denuncias Resueltas</label>

           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_99'
             name="sancion.indicador_99.porcentaje"
             value={porcentaje_indicador_99}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de Observaciones</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_100}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_100'
                    name="sancion.indicador_100.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de Procedimiento De Responsabilidad Administrativa</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_100}
             inputStyle ={{width: '50%'}}
             id='indicador_100'
             name="sancion.indicador_100.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De inicio de procedimientos de responsabilidades derivados de las auditorías</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_100'
             name="sancion.indicador_100.porcentaje"
             value={porcentaje_indicador_100}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de Quejas y Denuncias Recibidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_101}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_101'
                    name="sancion.indicador_101.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Total de Quejas y Denuncias Resueltas</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_101}
             inputStyle ={{width: '50%'}}
             id='indicador_101'
             name="sancion.indicador_101.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% Quejas y denuncias que derivan en sanciones administrativas y/o penales a servidores públicos</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_101'
             name="sancion.indicador_101.porcentaje"
             value={porcentaje_indicador_101}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de procedimientos penales</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_102}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_102'
                    name="sancion.indicador_102.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Servidores sancionados</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_102}
             inputStyle ={{width: '50%'}}
             id='indicador_102'
             name="sancion.indicador_102.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De servidores sancionados  </label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_102'
             name="sancion.indicador_102.porcentaje"
             value={porcentaje_indicador_102}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de procedimientos penales </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_103}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_103'
                    name="sancion.indicador_103.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Particulares sancionados</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_103}
             inputStyle ={{width: '50%'}}
             id='indicador_103'
             name="sancion.indicador_103.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Particulares sancionados</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_103'
             name="sancion.indicador_103.porcentaje"
             value={porcentaje_indicador_103}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label> Total de Sentencias Emitidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_104}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_104'
                    name="sancion.indicador_104.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Sentencias Condenatorias Obtenidas</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_104}
             inputStyle ={{width: '50%'}}
             id='indicador_104'
             name="sancion.indicador_104.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Sentencias Condenatorias Obtenidas</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_104'
             name="sancion.indicador_104.porcentaje"
             value={porcentaje_indicador_104}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de Sentencias condenatorias</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_105}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_105'
                    name="sancion.indicador_105.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Sentencias condenatorias firmes</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_105}
             inputStyle ={{width: '50%'}}
             id='indicador_105'
             name="sancion.indicador_105.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Sentencias Condenatorias Firmes</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_105'
             name="sancion.indicador_105.porcentaje"
             value={porcentaje_indicador_105}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />


              </div>





              </Grid>
              <Grid item xs={6}>

            </Grid>
              </Grid>
              <TitleCard title="Fiscalía(Recuperación)" />
              <span>Ingresos a la hacienda pública </span><br/>
              <Grid container xs={12} spacing={1}>
              <div class="settings">
                    <label>
                   Por cobro de sanciones económicas o indemnizaciones por faltas administrativas o hechos de corrupción
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_106}
             inputStyle ={{width: '50%'}}
             id='indicador_106'
             name="recuperacion.indicador_106"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
           <label>
           Montos recuperados por decomiso de bienes producto de hechos ilícitos
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_107}
             inputStyle ={{width: '50%'}}
             id='indicador_107'
             name="recuperacion.indicador_107"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
                    <label>
                    Montos recuperados por extinción de dominio
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_108}
             inputStyle ={{width: '50%'}}
             id='indicador_108'
             name="recuperacion.indicador_108"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           </div>

              </Grid>
              <Button
             type="submit"
             fullWidth
             variant="contained"
             color="primary"
             size="large"
             disabled={field_disabled === true ? true : false}
             // className={classes.submit}
           >
             Enviar
           </Button>
            </Grid>
                   </form>
        </div>
        </div>
          );
}

function calcular(){
  if(document.getElementById("indicador_88").value > 0
  && document.getElementById("total_indicador_88").value > 0){
  var resultado = document.getElementById("indicador_88").value/
                document.getElementById("total_indicador_88").value
  document.getElementById("porcentaje_indicador_88").value = ((resultado.toFixed(2))*100).toString() + " %";
  }





if(document.getElementById("indicador_91").value > 0
  && document.getElementById("total_indicador_91").value > 0){
  var resultado = document.getElementById("indicador_91").value/
                document.getElementById("total_indicador_91").value
  document.getElementById("porcentaje_indicador_91").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_92").value > 0
  && document.getElementById("total_indicador_92").value > 0){
  var resultado = document.getElementById("indicador_92").value/
                document.getElementById("total_indicador_92").value
  document.getElementById("porcentaje_indicador_92").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_93").value > 0
  && document.getElementById("total_indicador_93").value > 0){
  var resultado = document.getElementById("indicador_93").value/
                document.getElementById("total_indicador_93").value
  document.getElementById("porcentaje_indicador_93").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_94").value > 0
  && document.getElementById("total_indicador_94").value > 0){
  var resultado = document.getElementById("indicador_94").value/
                document.getElementById("total_indicador_94").value
  document.getElementById("porcentaje_indicador_94").value = ((resultado.toFixed(2))*100).toString() + " %";
  }



if(document.getElementById("indicador_96").value > 0
  && document.getElementById("total_indicador_96").value > 0){
  var resultado = document.getElementById("indicador_96").value/
                document.getElementById("total_indicador_96").value
  document.getElementById("porcentaje_indicador_96").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_97").value > 0
  && document.getElementById("total_indicador_97").value > 0){
  var resultado = document.getElementById("indicador_97").value/
                document.getElementById("total_indicador_97").value
  document.getElementById("porcentaje_indicador_97").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_98").value > 0
  && document.getElementById("total_indicador_98").value > 0){
  var resultado = document.getElementById("indicador_98").value/
                document.getElementById("total_indicador_98").value
  document.getElementById("porcentaje_indicador_98").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_99").value > 0
  && document.getElementById("total_indicador_99").value > 0){
  var resultado = document.getElementById("indicador_99").value/
                document.getElementById("total_indicador_99").value
  document.getElementById("porcentaje_indicador_99").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_100").value > 0
  && document.getElementById("total_indicador_100").value > 0){
  var resultado = document.getElementById("indicador_100").value/
                document.getElementById("total_indicador_100").value
  document.getElementById("porcentaje_indicador_100").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_101").value > 0
  && document.getElementById("total_indicador_101").value > 0){
  var resultado = document.getElementById("indicador_101").value/
                document.getElementById("total_indicador_101").value
  document.getElementById("porcentaje_indicador_101").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_102").value > 0
  && document.getElementById("total_indicador_102").value > 0){
  var resultado = document.getElementById("indicador_102").value/
                document.getElementById("total_indicador_102").value
  document.getElementById("porcentaje_indicador_102").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_103").value > 0
  && document.getElementById("total_indicador_103").value > 0){
  var resultado = document.getElementById("indicador_103").value/
                document.getElementById("total_indicador_103").value
  document.getElementById("porcentaje_indicador_103").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_104").value > 0
  && document.getElementById("total_indicador_104").value > 0){
  var resultado = document.getElementById("indicador_104").value/
                document.getElementById("total_indicador_104").value
  document.getElementById("porcentaje_indicador_104").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_105").value > 0
  && document.getElementById("total_indicador_105").value > 0){
  var resultado = document.getElementById("indicador_105").value/
                document.getElementById("total_indicador_105").value
  document.getElementById("porcentaje_indicador_105").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


}

function getData(data) {
  var data = {};

  var form = document.getElementById("FiscaliaForm");
  var allInputs = form.getElementsByTagName('input');
  for (let i = 0; i < allInputs.length; i++) {
    var str = allInputs[i].name;
    var val = allInputs[i].value;
    var strArr = str.split('.');
    generateObj(data, strArr, val);

    function generateObj(obj, arr, val) {
      //alert(val);
      if (arr.length === 1) {
        if(val==""){
          val="NR";
        }
        obj[arr[0]] = val
        return;
      }

      var restArr = arr.splice(1);
      if (!obj[arr[0]]) {
        obj[arr[0]] = {};
      }
      generateObj(obj[arr[0]], restArr, val);
    }

  };
  data["year"] = document.getElementById("year").value
  //alert(data);
  return data;
}

export default Fiscalia;