import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { removeUserSession } from '../common/Utils';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router";
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import Drawer from '@material-ui/core/Drawer';
import MenuEditor from './MenuEditor';

const useStyles = makeStyles((theme) => ({
    Avatar: {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.light,
    }
  }));

  const ITEM_HEIGHT = 48;
  function MenuDisplay(props) {
    const { match } = props;
    return <MenuEditor match={match} ></MenuEditor>
    }
 

  const HeaderEditor = (props) => {

  
    const { match } = props;
    let nombreUsuario = sessionStorage.getItem("nombre");
    let perfilUsuario = sessionStorage.getItem("email");
    let  history =  useHistory();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [nombre_usuario, set_nombre_usuario] = React.useState(perfilUsuario); 
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openSubmenu = Boolean(anchorEl);


    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
      open ? setOpen(false) : setOpen(true)
    };
    


    const handleSalirSistema= ()=>{
      removeUserSession();
      set_nombre_usuario("");
      history.push('/sign_in');
    }
    
    if (perfilUsuario) {
    return (
      <nav class="navbar navbar-dark bg-light navbar-expand-sm bg-primary">
      <div class="navbar-left col-md-2 col-sm-2">

      </div>
      <IconButton
            color="default"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            //className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuOpenIcon />
          </IconButton>
  <div class="navbar-center col-md-8 col-sm-8">
        <img src="https://static.wixstatic.com/media/5eb255_f46d8f0aef154e108d1e26c5dff38f2c~mv2.png/v1/fill/w_125,h_36,al_c,q_85,usm_0.66_1.00_0.01/logo-02.webp" alt="logo-02.png" style={{ width:125 + "px", height:36 + "px", objectFit:"cover" }} />
                     <img src="https://static.wixstatic.com/media/5eb255_8e874aa1936a4a39a57ce75c7ee9c310~mv2.png/v1/fill/w_152,h_36,al_c,q_85,usm_0.66_1.00_0.01/EGob_Horizontal_Negro-02.webp" alt="EGob_Horizontal_Negro-02.png" style= {{width:152 + "px", height: 36 + "px", objectFit:"cover" }} />
                     </div>

        <div class="navbar-right col-md-2 col-sm-2">

          <div>

          <Grid container xs={12} spacing={1}>
          <Grid item xs={7}>
        <Typography variant="subtitle1" display="block" color='initial'>
            {nombreUsuario}
          </Typography>
          </Grid>
          <Grid item xs={5}>
            <Avatar
              className={classes.Avatar}
              onClick={handleSalirSistema}
              src="#"
            />
             </Grid>

          </Grid>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={openSubmenu}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem onClick={handleSalirSistema}>Salir</MenuItem>
            </Menu>
            <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >

            <MenuDisplay match={match}></MenuDisplay>
          </Drawer>

          </div>
        </div>
    </nav>
    )
    }else{
      return (
        <nav class="navbar navbar-dark bg-light navbar-expand-sm bg-primary">
        <div class="navbar-left col-md-2 col-sm-2">
  
        </div>
  
    <div class="navbar-center col-md-8 col-sm-8">
          <img src="https://static.wixstatic.com/media/5eb255_f46d8f0aef154e108d1e26c5dff38f2c~mv2.png/v1/fill/w_125,h_36,al_c,q_85,usm_0.66_1.00_0.01/logo-02.webp" alt="logo-02.png" style={{ width:125 + "px", height:36 + "px", objectFit:"cover" }} />
                       <img src="https://static.wixstatic.com/media/5eb255_8e874aa1936a4a39a57ce75c7ee9c310~mv2.png/v1/fill/w_152,h_36,al_c,q_85,usm_0.66_1.00_0.01/EGob_Horizontal_Negro-02.webp" alt="EGob_Horizontal_Negro-02.png" style= {{width:152 + "px", height: 36 + "px", objectFit:"cover" }} />
                       </div>
  
          <div class="navbar-right col-md-2 col-sm-2">
  
            
          </div>
      </nav>
      )
    }
    }


export default HeaderEditor;
