import React,{useEffect,useState} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import { removeIndicadorSession, getDependencia ,getIndicador} from '../common/Utils';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {enviarDatosCapturados,editarRegistro} from '../common/Api';
import API from '../common/Api';
import TitleCard from '../dashboard/TitleCard';
import './Form.css'


const useStyles = makeStyles((theme) => ({

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

      offset:theme.mixins.toolbar

    }));


const Tribunal = (props) => {
    const classes = useStyles();
    const indicador = getIndicador();
    const dependencia = getDependencia();
    if(dependencia!="Tribunal"){
      removeIndicadorSession();
    }
    var resultado=""
    const [indicador_22,set_indicador_22] = useState();
const [total_indicador_22,set_total_indicador_22] = useState();
const [porcentaje_indicador_22,set_porcentaje_indicador_22] = useState();
const [indicador_23,set_indicador_23] = useState();
const [total_indicador_23,set_total_indicador_23] = useState();
const [porcentaje_indicador_23,set_porcentaje_indicador_23] = useState();
const [indicador_24,set_indicador_24] = useState();
const [total_indicador_24,set_total_indicador_24] = useState();
const [porcentaje_indicador_24,set_porcentaje_indicador_24] = useState();
const [indicador_25,set_indicador_25] = useState();
const [total_indicador_25,set_total_indicador_25] = useState();
const [porcentaje_indicador_25,set_porcentaje_indicador_25] = useState();
const [indicador_26,set_indicador_26] = useState();
const [total_indicador_26,set_total_indicador_26] = useState();
const [porcentaje_indicador_26,set_porcentaje_indicador_26] = useState();
const [indicador_27,set_indicador_27] = useState();
const [total_indicador_27,set_total_indicador_27] = useState();
const [porcentaje_indicador_27,set_porcentaje_indicador_27] = useState();
const [indicador_28,set_indicador_28] = useState();
const [total_indicador_28,set_total_indicador_28] = useState();
const [porcentaje_indicador_28,set_porcentaje_indicador_28] = useState();
const [indicador_29,set_indicador_29] = useState();
const [total_indicador_29,set_total_indicador_29] = useState();
const [porcentaje_indicador_29,set_porcentaje_indicador_29] = useState();
const [indicador_30,set_indicador_30] = useState();
const [total_indicador_30,set_total_indicador_30] = useState();
const [porcentaje_indicador_30,set_porcentaje_indicador_30] = useState();
const [indicador_31,set_indicador_31] = useState();
const [total_indicador_31,set_total_indicador_31] = useState();
const [porcentaje_indicador_31,set_porcentaje_indicador_31] = useState();
const [indicador_32,set_indicador_32] = useState();
const [total_indicador_32,set_total_indicador_32] = useState();
const [porcentaje_indicador_32,set_porcentaje_indicador_32] = useState();
const [indicador_33,set_indicador_33] = useState();
const [total_indicador_33,set_total_indicador_33] = useState();
const [porcentaje_indicador_33,set_porcentaje_indicador_33] = useState();
const [indicador_34,set_indicador_34] = useState();
const [total_indicador_34,set_total_indicador_34] = useState();
const [porcentaje_indicador_34,set_porcentaje_indicador_34] = useState();
const [indicador_35,set_indicador_35] = useState();
const [total_indicador_35,set_total_indicador_35] = useState();
const [porcentaje_indicador_35,set_porcentaje_indicador_35] = useState();
const [indicador_36,set_indicador_36] = useState();
const [total_indicador_36,set_total_indicador_36] = useState();
const [porcentaje_indicador_36,set_porcentaje_indicador_36] = useState();
const [indicador_37,set_indicador_37] = useState();
const [total_indicador_37,set_total_indicador_37] = useState();
const [porcentaje_indicador_37,set_porcentaje_indicador_37] = useState();
const [indicador_38,set_indicador_38] = useState();
const [total_indicador_38,set_total_indicador_38] = useState();
const [porcentaje_indicador_38,set_porcentaje_indicador_38] = useState();
const [indicador_39,set_indicador_39] = useState();
const [total_indicador_39,set_total_indicador_39] = useState();
const [porcentaje_indicador_39,set_porcentaje_indicador_39] = useState();


    useEffect(() =>{
      if(indicador){
        API.get("/api/indicador/"+indicador)
        .then(response => {

              set_select_disabled(true);
              if(response.data.datos.year=="2020" || response.data.datos.year=="2019"){
                set_field_disabled(true)
              }else{
                set_field_disabled(false)
              }
              set_year(response.data.datos.year);
              set_total_indicador_22(response.data.datos.investigacion.indicador_22.total);
set_indicador_22(response.data.datos.investigacion.indicador_22.numerador);
set_porcentaje_indicador_22(response.data.datos.investigacion.indicador_22.porcentaje);
set_total_indicador_23(response.data.datos.sancion.indicador_23.total);
set_indicador_23(response.data.datos.sancion.indicador_23.numerador);
set_porcentaje_indicador_23(response.data.datos.sancion.indicador_23.porcentaje);
set_total_indicador_24(response.data.datos.sancion.indicador_24.total);
set_indicador_24(response.data.datos.sancion.indicador_24.numerador);
set_porcentaje_indicador_24(response.data.datos.sancion.indicador_24.porcentaje);
set_total_indicador_25(response.data.datos.sancion.indicador_25.total);
set_indicador_25(response.data.datos.sancion.indicador_25.numerador);
set_porcentaje_indicador_25(response.data.datos.sancion.indicador_25.porcentaje);
set_total_indicador_26(response.data.datos.sancion.indicador_26.total);
set_indicador_26(response.data.datos.sancion.indicador_26.numerador);
set_porcentaje_indicador_26(response.data.datos.sancion.indicador_26.porcentaje);
set_total_indicador_27(response.data.datos.sancion.indicador_27.total);
set_indicador_27(response.data.datos.sancion.indicador_27.numerador);
set_porcentaje_indicador_27(response.data.datos.sancion.indicador_27.porcentaje);

set_indicador_28(response.data.datos.sancion.indicador_28);
set_total_indicador_29(response.data.datos.sancion.indicador_29.total);
set_indicador_29(response.data.datos.sancion.indicador_29.numerador);
set_porcentaje_indicador_29(response.data.datos.sancion.indicador_29.porcentaje);
set_total_indicador_30(response.data.datos.sancion.indicador_30.total);
set_indicador_30(response.data.datos.sancion.indicador_30.numerador);
set_porcentaje_indicador_30(response.data.datos.sancion.indicador_30.porcentaje);
set_total_indicador_31(response.data.datos.sancion.indicador_31.total);
set_indicador_31(response.data.datos.sancion.indicador_31.numerador);
set_porcentaje_indicador_31(response.data.datos.sancion.indicador_31.porcentaje);
set_total_indicador_32(response.data.datos.sancion.indicador_32.total);
set_indicador_32(response.data.datos.sancion.indicador_32.numerador);
set_porcentaje_indicador_32(response.data.datos.sancion.indicador_32.porcentaje);
set_total_indicador_33(response.data.datos.sancion.indicador_33.total);
set_indicador_33(response.data.datos.sancion.indicador_33.numerador);
set_porcentaje_indicador_33(response.data.datos.sancion.indicador_33.porcentaje);
set_indicador_34(response.data.datos.sancion.indicador_34);
set_indicador_35(response.data.datos.sancion.indicador_35);
set_indicador_36(response.data.datos.sancion.indicador_36);
set_indicador_37(response.data.datos.recuperacion.indicador_37);
set_indicador_38(response.data.datos.recuperacion.indicador_38);
set_indicador_39(response.data.datos.recuperacion.indicador_39);
            }).catch(error => {
                  return error;
          });
      }

    },[])
    const onSubmit = (event) => {

        event.preventDefault();
        const data = new FormData(event.target);
        var json_data = getData(data);
        var estado = sessionStorage["entidad_federativa"];
        console.log(json_data);
        if(!indicador){
         enviarDatosCapturados("Tribunal",json_data,estado,props);
        }else{
         editarRegistro(indicador,"Tribunal",estado,json_data,props);
        }
      };

      const  [select_disabled, set_select_disabled] = useState(false);
      const  [field_disabled, set_field_disabled] = useState(false);
      const  [year,set_year] = useState();
      const onChange = (e) => {

         set_indicador_22(document.getElementById('indicador_22').value);
         set_total_indicador_22(document.getElementById('total_indicador_22').value);
         resultado = (document.getElementById('indicador_22').value/
         document.getElementById('total_indicador_22').value)*100
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_22(((resultado.toFixed(2))).toString() + " %");
         }else{
           set_porcentaje_indicador_22("0%");
         }

         set_indicador_23(document.getElementById('indicador_23').value);
         set_total_indicador_23(document.getElementById('total_indicador_23').value);
         resultado = (document.getElementById('indicador_23').value/
         document.getElementById('total_indicador_23').value)*100
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_23(((resultado.toFixed(2))).toString() + " %");
         }else{
           set_porcentaje_indicador_23("0%");
         }

         set_indicador_24(document.getElementById('indicador_24').value);
         set_total_indicador_24(document.getElementById('total_indicador_24').value);
         resultado = document.getElementById('indicador_24').value/
         document.getElementById('total_indicador_24').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_24(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_24("0%");
         }

         set_indicador_25(document.getElementById('indicador_25').value);
         set_total_indicador_25(document.getElementById('total_indicador_25').value);
         resultado = document.getElementById('indicador_25').value/
         document.getElementById('total_indicador_25').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_25(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_25("0%");
         }

         set_indicador_26(document.getElementById('indicador_26').value);
         set_total_indicador_26(document.getElementById('total_indicador_26').value);
         resultado = document.getElementById('indicador_26').value/
         document.getElementById('total_indicador_26').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_26(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_26("0%");
         }

         set_indicador_27(document.getElementById('indicador_27').value);
         set_total_indicador_27(document.getElementById('total_indicador_27').value);
         resultado = document.getElementById('indicador_27').value/
         document.getElementById('total_indicador_27').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_27(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_27("0%");
         }

         set_indicador_28(document.getElementById('indicador_28').value);

         set_indicador_29(document.getElementById('indicador_29').value);
         set_total_indicador_29(document.getElementById('total_indicador_29').value);
         resultado = (document.getElementById('indicador_29').value/
         document.getElementById('total_indicador_29').value)*100
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_29(((resultado.toFixed(2))).toString() + " %");
         }else{
           set_porcentaje_indicador_29("0%");
         }

         set_indicador_30(document.getElementById('indicador_30').value);
         set_total_indicador_30(document.getElementById('total_indicador_30').value);
         resultado = document.getElementById('indicador_30').value/
         document.getElementById('total_indicador_30').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_30(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_30("0%");
         }

         set_indicador_31(document.getElementById('indicador_31').value);
         set_total_indicador_31(document.getElementById('total_indicador_31').value);
         resultado = document.getElementById('indicador_31').value/
         document.getElementById('total_indicador_31').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_31(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_31("0%");
         }

         set_indicador_32(document.getElementById('indicador_32').value);
         set_total_indicador_32(document.getElementById('total_indicador_32').value);
         resultado = document.getElementById('indicador_32').value/
         document.getElementById('total_indicador_32').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_32(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_32("0%");
         }

         set_indicador_33(document.getElementById('indicador_33').value);
         set_total_indicador_33(document.getElementById('total_indicador_33').value);
         resultado = document.getElementById('indicador_33').value/
         document.getElementById('total_indicador_33').value
         if(!Number.isNaN(resultado)){
           set_porcentaje_indicador_33(((resultado.toFixed(2))*100).toString() + " %");
         }else{
           set_porcentaje_indicador_33("0%");
         }

         set_indicador_34(document.getElementById('indicador_34').value);


         set_indicador_35(document.getElementById('indicador_35').value);


         set_indicador_36(document.getElementById('indicador_36').value);

         set_indicador_37(document.getElementById('indicador_37').value);


         set_indicador_38(document.getElementById('indicador_38').value);


         set_indicador_39(document.getElementById('indicador_39').value);

          calcular();
      };

      const { match } = props;
    return (

      <div className="auth-wrapper">
      <div className="auth-inner">

          <form
            onSubmit={onSubmit}
             autoComplete="off"
             id="TribunalForm"
             className={classes.form}
           >
               <TitleCard title="Tribunal(Investigación)" />

              <Grid item xs={1}>
              <FormControl variant="filled" className={classes.formControl}>
                  <Select
                    native

                    value={year}

                    disabled={select_disabled === true ? true : false}
                    inputProps={{
                      name: 'year',
                      id: 'year',
                    }}
                  >
 <option value={year}>{year}</option>
                    <option value="2021">2021</option>
                  <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </Select>
                  </FormControl>
              </Grid>
              <Grid container xs={12} spacing={1}>
              <span></span>

              <Grid item xs={12}>
              <div class="settings">

              <label>Total de demandas recibidas </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_22}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_22'
                    name="investigacion.indicador_22.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Demandas atendidas en tiempo</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_22}
             inputStyle ={{width: '50%'}}
             id='indicador_22'
             name="investigacion.indicador_22.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De demandas atendidas en tiempo </label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_22'
             name="investigacion.indicador_22.porcentaje"
             value={porcentaje_indicador_22}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />

                              </div>
                <TitleCard title="Tribunal(Sanción)" />
                <div class="settings">

                <label> Total de procedimientos administrativos iniciados</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_23}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_23'
                    name="sancion.indicador_23.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de procedimientos administrativos resueltos</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_23}
             inputStyle ={{width: '50%'}}
             id='indicador_23'
             name="sancion.indicador_23.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Procedimientos de responsabilidades administrativas por faltas graves de servidores públicos resueltos</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_23'
             name="sancion.indicador_23.porcentaje"
             value={porcentaje_indicador_23}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label> Total de procedimientos administrativos iniciados </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_24}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_24'
                    name="sancion.indicador_24.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de procedimientos administrativos resueltos</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_24}
             inputStyle ={{width: '50%'}}
             id='indicador_24'
             name="sancion.indicador_24.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Procedimientos de responsabilidades administrativas por faltas graves de particulares resueltos</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_24'
             name="sancion.indicador_24.porcentaje"
             value={porcentaje_indicador_24}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label> Total de juicios con cierre de instrucción con vencimiento en el periodo</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_25}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_25'
                    name="sancion.indicador_25.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de sentencias pronunciadas en el término de ley (45 días)</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_25}
             inputStyle ={{width: '50%'}}
             id='indicador_25'
             name="sancion.indicador_25.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De resoluciones emitidas en tiempo </label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_25'
             name="sancion.indicador_25.porcentaje"
             value={porcentaje_indicador_25}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de quejas y denuncias recibidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_26}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_26'
                    name="sancion.indicador_26.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de quejas y denuncias que derivan en sanción administrativa contra un servidor público</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_26}
             inputStyle ={{width: '50%'}}
             id='indicador_26'
             name="sancion.indicador_26.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De quejas y denuncias que derivan en sanciones administrativas a servidores públicos</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_26'
             name="sancion.indicador_26.porcentaje"
             value={porcentaje_indicador_26}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de Quejas y Denuncias Recibidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_27}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_27'
                    name="sancion.indicador_27.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Total de Quejas y Denuncias Resueltas</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_27}
             inputStyle ={{width: '50%'}}
             id='indicador_27'
             name="sancion.indicador_27.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% Quejas y Denuncias Resueltas</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_27'
             name="sancion.indicador_27.porcentaje"
             value={porcentaje_indicador_27}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Número Servidores con sanciones administrativas y/o penales </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={indicador_28}
                    inputStyle ={{width: '50%'}}
                    id='indicador_28'
                    name="sancion.indicador_28"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />







                <label>Total de procedimientos administrativos</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_29}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_29'
                    name="sancion.indicador_29.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Servidores públicos suspendidos </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_29}
             inputStyle ={{width: '50%'}}
             id='indicador_29'
             name="sancion.indicador_29.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% Servidores públicos suspendidos</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_29'
             name="sancion.indicador_29.porcentaje"
             value={porcentaje_indicador_29}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de procedimientos administrativos</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_30}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_30'
                    name="sancion.indicador_30.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Servidores públicos destituidos </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_30}
             inputStyle ={{width: '50%'}}
             id='indicador_30'
             name="sancion.indicador_30.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% Servidores públicos destituidos</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_30'
             name="sancion.indicador_30.porcentaje"
             value={porcentaje_indicador_30}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de procedimientos administrativos</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_31}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_31'
                    name="sancion.indicador_31.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Particulares sancionados</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_31}
             inputStyle ={{width: '50%'}}
             id='indicador_31'
             name="sancion.indicador_31.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Particulares sancionados  </label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_31'
             name="sancion.indicador_31.porcentaje"
             value={porcentaje_indicador_31}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de Sentencias Emitidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_32}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_32'
                    name="sancion.indicador_32.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Sentencias Condenatorias Obtenidas</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_32}
             inputStyle ={{width: '50%'}}
             id='indicador_32'
             name="sancion.indicador_32.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Sentencias Condenatorias Obtenidas</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_32'
             name="sancion.indicador_32.porcentaje"
             value={porcentaje_indicador_32}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de Sentencias condenatorias</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_33}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_33'
                    name="sancion.indicador_33.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Sentencias condenatorias firmes </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_33}
             inputStyle ={{width: '50%'}}
             id='indicador_33'
             name="sancion.indicador_33.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Sentencias Condenatorias Firmes</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_33'
             name="sancion.indicador_33.porcentaje"
             value={porcentaje_indicador_33}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Número de particulares y funcionarios con sanción firme</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={indicador_34}
                    inputStyle ={{width: '50%'}}
                    id='indicador_34'
                    name="sancion.indicador_34"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />





                <label>Número de Procedimientos de Responsabilidades que se encuentran en impugnación</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={indicador_35}
                    inputStyle ={{width: '50%'}}
                    id='indicador_35'
                    name="sancion.indicador_35"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />


             <label>Número de impugnaciones (recursos, juicios, amparos) otorgadas en contra de las sanciones</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_36}
             inputStyle ={{width: '50%'}}
             id='indicador_36'
             name="sancion.indicador_36"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />




           </div>
           <TitleCard title="Tribunal(Recuperación)" />
            <span>Ingresos a la hacienda pública </span><br/>


              <div class="settings">
                    <label>
                   Por cobro de sanciones económicas o indemnizaciones por faltas administrativas o hechos de corrupción
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_37}
             inputStyle ={{width: '50%'}}
             id='indicador_37'
             name="recuperacion.indicador_37"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
           <label>
           Montos recuperados por decomiso de bienes producto de hechos ilícitos
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_38}
             inputStyle ={{width: '50%'}}
             id='indicador_38'
             name="recuperacion.indicador_38"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
                   <label>
                    Montos recuperados por extinción de dominio
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_39}
             inputStyle ={{width: '50%'}}
             id='indicador_39'
             name="recuperacion.indicador_39"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
           </div>

              </Grid>
              </Grid>
           <Button
             type="submit"
             fullWidth
             variant="contained"
             color="primary"
             size="large"
             disabled={field_disabled === true ? true : false}
             // className={classes.submit}
           >
             Enviar
           </Button>
          </form>
        </div>
        </div>
          );
}


function calcular(){

  if(document.getElementById("indicador_22").value > 0
  && document.getElementById("total_indicador_22").value > 0){
  var resultado = document.getElementById("indicador_22").value/
                document.getElementById("total_indicador_22").value
  document.getElementById("porcentaje_indicador_22").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_23").value > 0
  && document.getElementById("total_indicador_23").value > 0){
  var resultado = document.getElementById("indicador_23").value/
                document.getElementById("total_indicador_23").value
  document.getElementById("porcentaje_indicador_23").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_24").value > 0
  && document.getElementById("total_indicador_24").value > 0){
  var resultado = document.getElementById("indicador_24").value/
                document.getElementById("total_indicador_24").value
  document.getElementById("porcentaje_indicador_24").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_25").value > 0
  && document.getElementById("total_indicador_25").value > 0){
  var resultado = document.getElementById("indicador_25").value/
                document.getElementById("total_indicador_25").value
  document.getElementById("porcentaje_indicador_25").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_26").value > 0
  && document.getElementById("total_indicador_26").value > 0){
  var resultado = document.getElementById("indicador_26").value/
                document.getElementById("total_indicador_26").value
  document.getElementById("porcentaje_indicador_26").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_27").value > 0
  && document.getElementById("total_indicador_27").value > 0){
  var resultado = document.getElementById("indicador_27").value/
                document.getElementById("total_indicador_27").value
  document.getElementById("porcentaje_indicador_27").value = ((resultado.toFixed(2))*100).toString() + " %";
  }




if(document.getElementById("indicador_29").value > 0
  && document.getElementById("total_indicador_29").value > 0){
  var resultado = document.getElementById("indicador_29").value/
                document.getElementById("total_indicador_29").value
  document.getElementById("porcentaje_indicador_29").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_30").value > 0
  && document.getElementById("total_indicador_30").value > 0){
  var resultado = document.getElementById("indicador_30").value/
                document.getElementById("total_indicador_30").value
  document.getElementById("porcentaje_indicador_30").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_31").value > 0
  && document.getElementById("total_indicador_31").value > 0){
  var resultado = document.getElementById("indicador_31").value/
                document.getElementById("total_indicador_31").value
  document.getElementById("porcentaje_indicador_31").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_32").value > 0
  && document.getElementById("total_indicador_32").value > 0){
  var resultado = document.getElementById("indicador_32").value/
                document.getElementById("total_indicador_32").value
  document.getElementById("porcentaje_indicador_32").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_33").value > 0
  && document.getElementById("total_indicador_33").value > 0){
  var resultado = document.getElementById("indicador_33").value/
                document.getElementById("total_indicador_33").value
  document.getElementById("porcentaje_indicador_33").value = ((resultado.toFixed(2))*100).toString() + " %";
  }










}

function getData(data) {
  var data = {};

  var form = document.getElementById("TribunalForm");
  var allInputs = form.getElementsByTagName('input');
  for (let i = 0; i < allInputs.length; i++) {
    var str = allInputs[i].name;
    var val = allInputs[i].value;
    var strArr = str.split('.');
    generateObj(data, strArr, val);

    function generateObj(obj, arr, val) {
      if (arr.length === 1) {
        if(val==""){
          val="NR";
        }
        obj[arr[0]] = val
        return;
      }

      var restArr = arr.splice(1);
      if (!obj[arr[0]]) {
        obj[arr[0]] = {};
      }
      generateObj(obj[arr[0]], restArr, val);
    }

  };
  data["year"] = document.getElementById("year").value
  //alert(data);
  return data;
}

export default Tribunal;