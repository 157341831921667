import React from 'react';


import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Map from './Map';



export default function ComplexGrid(props) {

  return (

     
     <Map></Map>

 
  );
};
