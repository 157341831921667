import {React,useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TitleCard from './TitleCard';
import MapCard from './MapCard';
import Grid from '@material-ui/core/Grid';
import Dropdown from '../site/Filters/dropdown';
import Table from './Table';
import DashboardComparacion from './DashboardComparacion';
import {updateChart} from './DashboardComparacion';
import {estadosConIndicadoresCapturados} from '../common/Api';
import './Dashboard.css';
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: '#303030',
      overflow: 'auto'
    },
    flags: {
      color: '#26c6da',
      fontSize: 16,
      fontWeight: 'bold',
      fontFamily: 'Arial'
    },
    indicator: {
      backgroundColor: '#26c6da'
    },
    text: {
      color: 'white'
    },
    boldtext: {
      color: 'black',
      fontSize: 17,
      fontWeight: 'bold',
      fontFamily: 'Arial'
    },
    dashboard: {
      flexFlow: "wrap",
      alignItems: "baseline",
      justifyItems: "flex-start"
    },
    tabpanel: {
      marginLeft: "auto",
      marginRight: "auto"
    }
  });
  


  const Dashboard = (props) => {



    const [estado_uno, set_estado_uno] = useState("");
    const [estado_dos, set_estado_dos] = useState("");
    const [area, set_area] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [charts, setCharts] = useState(0);

    useEffect(() => {
      set_estado_uno(estado_uno);
      set_estado_dos(estado_dos);
      set_area(area);
      //console.log(area)
      setIsLoading(true);
      var delay = 5000;
      setTimeout(function(){
        setIsLoading(false);
          
      },delay);

    },[estado_uno,estado_dos,area]);

    

    
        const classes = useStyles();
        return (
            <div id='alto-appbar'>
                   
                   <Grid container className={classes.dashboard}>
                   <TitleCard title="Mapa representativo" />
                   <MapCard>
                   </MapCard>    
                   </Grid>

                   <Grid container className={classes.dashboard}>
                   <TitleCard title="Tabla" />
                   <Table />
                   </Grid>

                   
                   <TitleCard title="Comparativa entre dos estados" />
                   <Grid container xs={12} className={classes.dashboard}>
                   <Grid item sm container xs={4}>
                   <Dropdown call="/api/entidades_federativas" 
                             set_estado_uno={set_estado_uno}  
                             set_estado_dos={set_estado_dos} 
                             set_area={set_area}
                             area={area} 
                              id = "estado_uno" name = "estado_uno"  
                              inputLabel='' defaultLabel='Seleccionar Entidad Federativa'/>
                     </Grid>
                     <Grid item sm container xs={4}>
                     <Dropdown call="/api/entidades_federativas"  
                                set_estado_uno={set_estado_uno}  
                                set_estado_dos={set_estado_dos} 
                                set_area={set_area}  
                                area={area}
                                id = "estado_dos" name = "estado_dos" 
                                inputLabel='' defaultLabel='Seleccionar Entidad Federativa'/>
                     
      
                     </Grid>
                     <Grid item sm container xs={4}>
                     <Dropdown call="/api/catalogos/areas" 
                               set_area={set_area}
                               area={area}   
                               set_estado_uno={set_estado_uno}  
                               set_estado_dos={set_estado_dos} 
                               id = "area" name = "area" 
                               inputLabel='' defaultLabel='Selecciona Indicador'/>
                   
                     </Grid>
                     
                   </Grid>


                   <Grid container className={classes.dashboard}>
                   {isLoading ? (
                    <p>Loading ...</p>
                  ):(
                   <DashboardComparacion estado_uno={estado_uno} 
                                         estado_dos={estado_dos}  
                                         area={area}/>)}
                                         
                   </Grid>
            </div>

        );
   
  }
  export default Dashboard;