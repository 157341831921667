
// return the user data from the session storage
export const getEmail= () => {
    const emailStr = sessionStorage.getItem('email');
    return sessionStorage.getItem('email') || null;
  }

  // return the token from the session storage
  export const getToken = () => {
    return sessionStorage.getItem('token') || null;
  }

  // return the id from the session storage
  export const getIdUser = () => {
    return sessionStorage.getItem('id') || null;
  }

    // return the id from the session storage
  export const getRol = () => {
      return sessionStorage.getItem('rol') || null;
  }

  // return the id from the session storage
  export const getEntidadFederativa = () => {
    return sessionStorage.getItem('entidad_federativa') || null;
  }
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('rol');
  }

  // set the token and user from the session storage
  export const setUserSession = (email, rol,nombre,entidad_federativa) => {
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('rol', rol);
    sessionStorage.setItem('nombre', nombre);
    sessionStorage.setItem('entidad_federativa', entidad_federativa);
    //sessionStorage.setItem('token', token);
  }

  export const setPorcentajesEntidad = (porcentajes)=> {
    console.log("AQUI")
    console.log( porcentajes["porcentaje_sistema_trasnparencia"])
    sessionStorage.setItem('porcentaje_uno', porcentajes["porcentaje_participacion_ciudadana"]);
    sessionStorage.setItem('porcentaje_dos', porcentajes["porcentaje_sistema_transparencia"]);
  }

  export const setIndicadorSession = (indicador,area) => {
    sessionStorage.setItem('indicador', indicador);
    sessionStorage.setItem('dependencia', area);
  }

  export const removeIndicadorSession = () => {
    console.log("Borrar sesion");
    sessionStorage.removeItem('indicador');
    sessionStorage.removeItem('dependencia');
  }

  export const getIndicador = () => {
    return sessionStorage.getItem('indicador') || null;
}

export const getPorcentajeUno = () => {
  return sessionStorage.getItem('porcentaje_uno') || null;
}

export const getPorcentajeDos = () => {

  return sessionStorage.getItem('porcentaje_dos') || null;
}

export const getDependencia = () => {
  return sessionStorage.getItem('dependencia') || null;
}
  export const isLogin = () => {
    if (sessionStorage.getItem('email')){
      return true;
    }else{
      return false;
    }

  }