import React, { useState, useEffect } from 'react'
import {sendPostPorcentajes,sendPutPorcentajes} from '../common/Api';
import {getPorcentajeUno,getPorcentajeDos,setPorcentajesEntidad} from '../common/Utils';
import {NotificationManager} from 'react-notifications';
var estado = sessionStorage["entidad_federativa"];
var porcentajes_nuevos = {};
const Porcentajes = (props) => {

  const [porcentaje, set_porcentaje] = useState({
    porcentaje_uno: getPorcentajeUno(),
    porcentaje_dos: getPorcentajeDos(),
  });
  const { porcentaje_uno, porcentaje_dos } = porcentaje;

  const onChange = (e) => {
    set_porcentaje({
        ...porcentaje,
        [e.target.name]: e.target.value,
    });
};
  const onSubmit = (e) => {

    e.preventDefault();
    if (porcentaje_uno.trim() === "" || porcentaje_dos.trim() === "") {
      NotificationManager.error('Existen campos vacios', 'Error');
      return;
    }
    porcentajes_nuevos = {
      "porcentaje_participacion_ciudadana":porcentaje_uno,
      "porcentaje_sistema_transparencia":porcentaje_dos
    }
    setPorcentajesEntidad(porcentajes_nuevos);

    sendPostPorcentajes(estado,porcentaje_uno, porcentaje_dos,props);

  };

return (
  <div className="auth-wrapper">
  <div className="auth-inner">
      <form onSubmit={onSubmit}>



      <div className="form-group">
          <label>Porcentaje de Participacion Ciudadana</label>
          <input type="porcentaje_uno" className="form-control"  id="porcentaje_uno"   name="porcentaje_uno"
                     value={porcentaje_uno} onChange={onChange}  placeholder=" Porcentaje Comité de Participación Ciudadana" />
      </div>

      <div className="form-group">
      <label>Porcentaje Sistema Transparencia</label>
          <input type="porcentaje_dos" className="form-control" id="porcentaje_dos"   name="porcentaje_dos"
                    value={porcentaje_dos}  onChange={onChange}   placeholder="Porcentaje Plataforma Nacional de Transparencia" />
      </div>


      <br/>
      <button type="submit"
              size="large"
              className="btn btn-primary btn-block">Ingresar
      </button>

      </form>
      </div>
      </div>
);
}

export default Porcentajes;