import React, { useEffect } from 'react';
import clsx from 'clsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { removeUserSession } from '../common/Utils';
import { isLogin } from '../common/Utils';
import Typography from '@material-ui/core/Typography';
import { Switch, Route, Redirect,useHistory } from "react-router";
import { NavLink } from 'react-router-dom'



 

  const HeaderLogin = (props) => {


    
  
      return (
        <nav class="navbar navbar-dark bg-light navbar-expand-sm bg-primary">
        <div class="navbar-left col-md-1 col-sm-1">
  
        </div>
  
    <div class="navbar-center col-md-5 col-sm-5" style= {{padding: 0}}>
      <table>         
        <tr>
          <td>
        <img src="https://static.wixstatic.com/media/5eb255_f46d8f0aef154e108d1e26c5dff38f2c~mv2.png/v1/fill/w_125,h_36,al_c,q_85,usm_0.66_1.00_0.01/logo-02.webp" alt="logo-02.png" style={{ width:125 + "px", height:36 + "px", objectFit:"cover" }} />
        </td>
        <td>
          <img src="https://static.wixstatic.com/media/5eb255_8e874aa1936a4a39a57ce75c7ee9c310~mv2.png/v1/fill/w_152,h_36,al_c,q_85,usm_0.66_1.00_0.01/EGob_Horizontal_Negro-02.webp" alt="EGob_Horizontal_Negro-02.png" style= {{width:152 + "px", height: 36 + "px", objectFit:"cover" }} />
          </td>
          </tr>
          </table>
 
    </div>
  
  
          <div class="navbar-right col-md-2 col-sm-2">
         
          </div>
      </nav>
      )
    }
 

export default HeaderLogin;
