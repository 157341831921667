import React,{useEffect,useState} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';

import { removeIndicadorSession, getDependencia ,getIndicador} from '../common/Utils';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {enviarDatosCapturados,editarRegistro} from '../common/Api';
import API from '../common/Api';
import TitleCard from '../dashboard/TitleCard';
import './Form.css'

const useStyles = makeStyles((theme) => ({

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

      offset:theme.mixins.toolbar

    }));


const Finanzas = (props) => {
    const classes = useStyles();
    const indicador = getIndicador();
    const dependencia = getDependencia();
    if(dependencia!="Finanzas"){
      removeIndicadorSession();
    }
    var resultado=""
    const  [select_disabled, set_select_disabled] = useState(false);
    const  [field_disabled, set_field_disabled] = useState(false);
    const  [year,set_year] = useState();
    const [indicador_83,set_indicador_83] = useState();
    const [total_indicador_83,set_total_indicador_83] = useState();
    const [porcentaje_indicador_83,set_porcentaje_indicador_83] = useState();
    const [indicador_84,set_indicador_84] = useState();
    const [total_indicador_84,set_total_indicador_84] = useState();
    const [porcentaje_indicador_84,set_porcentaje_indicador_84] = useState();
    const [indicador_85,set_indicador_85] = useState();
    const [indicador_86,set_indicador_86] = useState();
    const [indicador_87,set_indicador_87] = useState();

    useEffect(() =>{
      if(indicador){
        API.get("/api/indicador/"+indicador)
        .then(response => {
              set_select_disabled(true);
              if(response.data.datos.year=="2020" || response.data.datos.year=="2019"){
                set_field_disabled(true)
              }else{
                set_field_disabled(false)
              }
              set_year(response.data.datos.year);
              set_indicador_83(response.data.datos.recuperacion.indicador_83.numerador);
              set_total_indicador_83(response.data.datos.recuperacion.indicador_83.total);
              set_porcentaje_indicador_83(response.data.datos.recuperacion.indicador_83.porcentaje);
              set_indicador_84(response.data.datos.recuperacion.indicador_84.numerador);
              set_total_indicador_84(response.data.datos.recuperacion.indicador_84.total);
              set_porcentaje_indicador_84(response.data.datos.recuperacion.indicador_84.porcentaje);
              set_indicador_85(response.data.datos.recuperacion.indicador_85);
              set_indicador_86(response.data.datos.recuperacion.indicador_86);
              set_indicador_87(response.data.datos.recuperacion.indicador_87);

            }).catch(error => {
                  return error;
          });
      }

    },[]);

    const onChange = (e) => {
      set_indicador_83(document.getElementById('indicador_83').value);
      set_total_indicador_83(document.getElementById('total_indicador_83').value);
      resultado = document.getElementById('indicador_83').value/
      document.getElementById('total_indicador_83').value
      if(!Number.isNaN(resultado)){
        set_porcentaje_indicador_83(((resultado.toFixed(2))*100).toString() + " %");
      }else{
        set_porcentaje_indicador_83("0%");
      }

      set_indicador_84(document.getElementById('indicador_84').value);
      set_total_indicador_84(document.getElementById('total_indicador_84').value);
      resultado = document.getElementById('indicador_84').value/
      document.getElementById('total_indicador_84').value
      if(!Number.isNaN(resultado)){
        set_porcentaje_indicador_84(((resultado.toFixed(2))*100).toString() + " %");
      }else{
        set_porcentaje_indicador_84("0%");
      }

      set_indicador_85(document.getElementById('indicador_85').value);


      set_indicador_86(document.getElementById('indicador_86').value);

      set_indicador_87(document.getElementById('indicador_87').value);

      calcular();
    };


    const onSubmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.target);
      console.log(json_data);
      var json_data = getData(data);
      var estado = sessionStorage["entidad_federativa"];
      console.log(json_data);
      if(!indicador){
       enviarDatosCapturados("Finanzas",json_data,estado,props);
      }else{
       editarRegistro(indicador,"Finanzas",estado,json_data,props);
      }
      };



      const { match } = props;
    return (

      <div className="auth-wrapper">
      <div className="auth-inner">
          <TitleCard title="Finanzas(Recuperación)" />
          <form
            onSubmit={onSubmit}
             autoComplete="off"
             id="FinanzasForm"
             className={classes.form}
           >
               <Grid container xs={1} spacing={1}>
              <Grid item xs={1}>
              <FormControl variant="filled" className={classes.formControl}>
                  <Select
                    native

                    value={year}

                    disabled={select_disabled === true ? true : false}
                    inputProps={{
                      name: 'year',
                      id: 'year',
                    }}
                  >
                     <option value={year}>{year}</option>
                    <option value="2021">2021</option>
                  <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </Select>
                  </FormControl>
              </Grid>

            </Grid>
            <Grid container xs={12} spacing={1}>
              <Grid item xs={12}>

                  <div class="settings">
                    <label>
                    Total de créditos fiscales provenientes de sanciones económicas a servidores públicos
              </label>
              <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_83}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_83'
                    name="recuperacion.indicador_83.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />


           <label>
           créditos fiscales cobrados provinietes de sanciones económicas a servidores públicos
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_83}
             inputStyle ={{width: '50%'}}
             id='indicador_83'
             name="recuperacion.indicador_83.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
                               <label>
                               % De cobro de sanciones económicas por faltas administrativas a servidores públicos
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_83'
             name="recuperacion.indicador_83.porcentaje"
             value={porcentaje_indicador_83}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />

                    <label>
                    total de créditos fiscales provinientes de sanciones económicas a particulares
              </label>
              <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_84}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_84'
                    name="recuperacion.indicador_84.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />
                              <label>
                              créditos fiscales cobrados provinientes de sanciones económicas a particules
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_84}
             inputStyle ={{width: '50%'}}
             id='indicador_84'
             name="recuperacion.indicador_84.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

              <label>
              % De cobro de sanciones económicas por faltas administrativas a particulares
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_84'
             name="recuperacion.indicador_84.porcentaje"
             value={porcentaje_indicador_84}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />

                    <label>
                   Por cobro de sanciones económicas o indemnizaciones por faltas administrativas o hechos de corrupción
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_85}
             inputStyle ={{width: '50%'}}
             id='indicador_85'
             name="recuperacion.indicador_85"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
                    <label>
                    Por activos recuperados de actos de corrupción
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_86}
             inputStyle ={{width: '50%'}}
             id='indicador_86'
             name="recuperacion.indicador_86"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
                    <label>
                    Montos recuperados por extinción de dominio
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_87}
             inputStyle ={{width: '50%'}}
             id='indicador_87'
             name="recuperacion.indicador_87"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
           </div>
                  </Grid>
              <Button
             type="submit"
             fullWidth
             variant="contained"
             color="primary"
             size="large"
             disabled={field_disabled === true ? true : false}
             // className={classes.submit}
           >
             Enviar
           </Button>
            </Grid>
         </form>
        </div>
         </div>
          );
}

function calcular(){

  if(document.getElementById("indicador_83").value > 0
  && document.getElementById("total_indicador_83").value > 0){
  var resultado = document.getElementById("indicador_83").value/
                document.getElementById("total_indicador_83").value
  document.getElementById("porcentaje_indicador_83").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_84").value > 0
  && document.getElementById("total_indicador_84").value > 0){
  var resultado = document.getElementById("indicador_84").value/
                document.getElementById("total_indicador_84").value
  document.getElementById("porcentaje_indicador_84").value = ((resultado.toFixed(2))*100).toString() + " %";
  }
  }



  function getData(data) {
    var data = {};

    var form = document.getElementById("FinanzasForm");
    var allInputs = form.getElementsByTagName('input');
    for (let i = 0; i < allInputs.length; i++) {
      var str = allInputs[i].name;
      var val = allInputs[i].value;
      var strArr = str.split('.');
      generateObj(data, strArr, val);

      function generateObj(obj, arr, val) {
        //alert(val);
        if (arr.length === 1) {
          if(val==""){
            val="NR";
          }
          obj[arr[0]] = val
          return;
        }

        var restArr = arr.splice(1);
        if (!obj[arr[0]]) {
          obj[arr[0]] = {};
        }
        generateObj(obj[arr[0]], restArr, val);
      }

    };
    data["year"] = document.getElementById("year").value
    //alert(data);
    return data;
  }
export default Finanzas;