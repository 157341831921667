import React, { useState } from 'react';
import API from '../common/Api';
import Grid from '@material-ui/core/Grid';
import MUIDataTable from "mui-datatables";
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import Check from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { Switch, Route, Redirect,useHistory } from "react-router";

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import PropTypes from 'prop-types';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

let datos_capturados = [];
const drawerWidth = 320;

const customStyles = theme => ({
  BusinessAnalystRow: {
    '& td': { backgroundColor: 'red' },
  },
  GreyLine: {
    '& td': { backgroundColor: theme.palette.grey[200] },
  },
  NameCell: {
    fontWeight: 900,
  },
});

class ListaServidores extends React.Component {
  state = {
      data: [],
      open: true,
  };

  constructor(props) {
    super(props);
    this.data={};
    this.newCounter = [1];
    this.state = {
      labels:[],
      datasets:[],
      datasetsa:[],
      datasetsb: [],
      A: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.setAnchorEl = null;
    this.setOpen = true;
    this.ITEM_HEIGHT = 48;
    //this.nombreUsuario = localStorage.getItem("nombreUsuario");
  }

  getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          backgroundColor: "white"
        }
      },
      MUIDataTable: {
        root: {
          backgroundColor: 'white',
        },
        paper: {
          boxShadow: 'none',
        },
      },
      MuiToolbar: {
        root: {
          backgroundColor: 'blue',
        },
      },
      MuiTableCell: {
        head: {
          backgroundColor: 'purple',
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'blue',
        },
      },
      MuiTableFooter: {
        root: {
          '& .MuiToolbar-root': {
            backgroundColor: 'white',
          },
        },
      },
    },
  });

  handleClick = (event) => {
    this.setAnchorEl(event.currentTarget);
  };

  handleClose = () => {
    this.setAnchorEl(null);
  };

  handleDrawerOpen = () => {
    this.open ? this.setOpen(false) : this.setOpen(true)
  };

  open =this.state.open;
  anchorEl= this.state.anchor;
  openSubmenu = Boolean(this.anchorEl);

  componentDidMount() {
    var arreglo_tags = [];
    var datasets1 = [];
    var datasets2 = [];
    var datasets3 = [];
    var datasets4 = [];
    var datasets5 = [];
    var datasets6 = [];
    var datasets7 = [];
    var datasets8 = [];
    var label1 = "Estado";
    var label2 = "FuncionPublica";
    var label3 = "PoderJudicial";
    var label4 = "Transparencia";
    var label5 = "Tribunal";
    var label6 = "Auditoria";
    var label7 = "Finanzas";
    var label8 = "Fiscalia";
    var a = [];

    API.post('/api/dashboard_estados_tabla',

      {
        "limit": 10,
        "skip": 0
      }
    ).then(
      response => {
       
        
        response.data.forEach(function(item) {
          console.log(item);
          console.log(item[label1]);
          console.log(item[label5]);
          datasets1.push(item[label1]);

          if(item[label2]==1){
            datasets2.push(<Check/>);
          }else{
            datasets2.push(<ErrorOutlineRoundedIcon/>);
          }

          if(item[label3]==1){
            datasets3.push(<Check/>);
          }else{
            datasets3.push(<ErrorOutlineRoundedIcon/>);
          }

          if(item[label4]==1){
            datasets4.push(<Check/>);
          }else{
            datasets4.push(<ErrorOutlineRoundedIcon/>);
          }

          if(item[label5]==1){
            datasets5.push(<Check/>);
          }else{
            datasets5.push(<ErrorOutlineRoundedIcon/>);
          }

          if(item[label6]==1){
            datasets6.push(<Check/>);
          }else{
            datasets6.push(<ErrorOutlineRoundedIcon/>);
          }

          if(item[label7]==1){
            datasets7.push(<Check/>);
          }else{
            datasets7.push(<ErrorOutlineRoundedIcon/>);
          }

          if(item[label8]==1){
            datasets8.push(<Check/>);
          }else{
            datasets8.push(<ErrorOutlineRoundedIcon/>);
          }
        });

        for (var i = 0; i < datasets1.length; i++) {
          a[i] = [datasets1[i], datasets2[i], datasets3[i], datasets4[i], datasets5[i], datasets6[i], datasets7[i], datasets8[i]]
        };

        

        var index = 0;
        while (index < datasets1.length) {
          this.setState(prevState => ({
              datasets: [...prevState.datasets, datasets1[index]],
              datasetsa: [...prevState.datasetsa, datasets2[index]],
              datasetsb: [...prevState.datasetsb, datasets3[index]]
          }))
          index++;
        }

        index = 0;
        while (index < a.length) {
          this.setState(prevState => ({
              A: [...prevState.A, a[index]],
              }))
          index++;
        }




        }).catch(error => {
            console.log("error");
        });
    }

    columns = [
      "Estado",
      {
        name: "FuncionPublica",
        label: "Función Pública",
        
      },
      {
        name: "PoderJudicial",
        label: "Poder Judicial",
        
      },
      {
        name: "Transparencia",
        label: "Transparencia",
       
      },
      {
        name: "Tribunal",
        label: "Tribunal",
       
      },
      {
        name: "Auditoria",
        label: "Auditoria",
        
        
      },
      {
        name: "Finanzas",
        label: "Finanzas",
       
      },
      {
        name: "Fiscalia",
        label: "Fiscalia",

      },
    ];

    render() {
      return (
        <div className="col-sm-12 py-3">
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  title={""}
                  data={this.state.A}
                  columns={this.columns}
                  options={{
                    selectableRows: false // <===== will turn off checkboxes in rows

                  }}
                />
              </MuiThemeProvider>
            </Grid>
          </Grid>
        </div>
      );
    }
  }


export default withStyles(customStyles, { name: 'ExampleCard.js' })(ListaServidores);
