import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_mexicoLow from "@amcharts/amcharts4-geodata/mexicoLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Card from 'react-bootstrap/Card'
import API from '../common/Api';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
var estados_con_indicadores = [];
var indicadores_llenados = {};
var indicadores_llenados_clave = {};
var estado_seleccionado  ="";


class Map extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      estado: ""
    };
  }

  componentDidMount() {

    API.post("/api/dashboard_estados")
    .then(response => {

        response.data["data"].map(function(row,i) {
            estados_con_indicadores.push(row[1]);
            indicadores_llenados[row[0]]= row[2];
            indicadores_llenados_clave[row[1]]= row[2];

          })
          //console.log(estados_con_indicadores);
          var chart = am4core.create("chartdiv", am4maps.MapChart);

          // Set map definition
          chart.geodataSource.url = "https://www.amcharts.com/lib/4/geodata/json/mexicoLow.json";
          chart.geodataSource.events.on("parseended", function(event) {
            let data = [];
            for(var i = 0; i < event.target.data.features.length; i++) {

              if(estados_con_indicadores.indexOf(event.target.data.features[i].id) != -1
              && indicadores_llenados_clave[event.target.data.features[i].id] > 0){
                data.push({
                  id: event.target.data.features[i].id,
                  value: 2,
                  name: event.target.data.features[i].name,
                  clave: event.target.data.features[i].clave,
                  color: "red"
                })
              }else{
                data.push({
                  id: event.target.data.features[i].id,
                  value: 1,
                  name: event.target.data.features[i].name,
                  clave: event.target.data.features[i].clave,
                  color: "yellow"
                })
              }


            }
            //console.log(data);
            polygonSeries.data = data;
          })

          // Set projection
          chart.projection = new am4maps.projections.Mercator();

          // Add zoom control
          chart.zoomControl = new am4maps.ZoomControl();

          // Set initial zoom
          chart.homeZoomLevel = 1;

          // Create map polygon series
          var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
          polygonSeries.mapPolygons.template.strokeWidth = 0.5;


          // Make map load polygon (like country names) data from GeoJSON
          polygonSeries.useGeodata = true;

          // Configure series
          var polygonTemplate = polygonSeries.mapPolygons.template;

          polygonTemplate.tooltipText = "{name}: {value}";
          polygonTemplate.propertyFields.fill = "color";

          // Create hover state and set alternative fill color
          var hs = polygonTemplate.states.create("hover");
          hs.properties.fill = am4core.color("#367B25");


          // Create active state
          var activeState = polygonTemplate.states.create("active");
          activeState.properties.fill = chart.colors.getIndex(1);

          // Create an event to toggle "active" state
          polygonTemplate.events.on("hit", function (event) {
            estado_seleccionado = event.target.dataItem.dataContext.name;
            document.getElementById('estado_seleccionado').innerHTML = estado_seleccionado;
            document.getElementById('indicadores').innerHTML = indicadores_llenados[estado_seleccionado]+"/7";
          })
    }).catch(error => {
            return error;
    });

    this.setState({estado:estado_seleccionado});
    // Create map instance


  }



  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }


    render() {
    return (


      <Grid container xs={12}>

                <Grid item xs={6}>
                     <Paper class="paper">
                            <div id="chartdiv" style={{ width: "100%", height: "400px" }}></div>
                      </Paper>
                </Grid>

                  <Grid item xs={6}>
                <Paper class="paper">
                <Card
                style={{ width: 'rem' }}
                className="mb-3">
                  <Card.Header > INFO</Card.Header>
                  <Card.Body>
                    <Card.Title><span class="estado_titulo" id="estado_seleccionado"></span> </Card.Title>
                    <Card.Text>
                      Indicadores llenados<br/>
                        <span class="indicadores" id="indicadores"></span>
                    </Card.Text>
                  </Card.Body>
                </Card>

                      </Paper>
                  </Grid>
                  </Grid>
    );
    }
}

function FillCard() {
 //alert("Aqui");
}
export default Map;