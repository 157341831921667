import React from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Particulas } from './Particulas';
import { deepOrange } from '@material-ui/core/colors';
const useStyles = makeStyles((theme) => ({


    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "4rem 0",
      paddingTop:'2rem',
      backgroundColor:'#232f3e',
      color:'#fff',
      // margin:'1rem',
      borderRadius:'10px',
      position:'absolute',
      top:'0rem',
      width:'100%',
      // height:'100px'
    },
    Avatar:{
      backgroundColor: theme.palette.primary.dark
    },
    Divider:{
      width:'30%',
      height:'1px',
      backgroundColor: theme.palette.primary.dark
    },
    particulas:{
      color:theme.palette.primary.dark
    }

  }));

const InfoUsuario = () => {
  let nombreUsuario = sessionStorage.getItem("nombre");
  let pefilUsuario = sessionStorage.getItem("rol");

  //let nombreUsuario = "Editor Oaxaca";
  //let pefilUsuario = "Usuario Editor";

    const classes = useStyles();
    return (
      <>
      <Particulas
      style={{
        position:'absolute',
        top:0
      }}
      width='100%'
      height='auto'
      params={{
        particles: {
          "collisions": {
            "enable": false
        },
          number: {
            value: 10,
          },
          color: {
            value: '#969292',
          },
          size: {
            value: 10,
          },
          links: {
            color: "#969292",
            distance: 50,
            enable: true,
            opacity: 1,
            width: 3,
          },

          line_linked: {
            shadow: {
              enable: true,
              color: "#3CA9D1",
              blur: 5,
            },
          },
        },
        detectRetina: true,
      }}
      ></Particulas>
      <Box className={classes.root}>

        <Avatar alt={nombreUsuario} src="#" className={classes.Avatar} />
        <Typography variant="subtitle1" display="block" color='initial'>
          {nombreUsuario}
        </Typography>
        <Typography variant="subtitle2" display="block" gutterBottom>
          {pefilUsuario}
        </Typography>
        <div className={classes.Divider}></div>
      </Box>
      </>
    );
}

export default InfoUsuario
