import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DonutChart from './charts/donutChart'
import RecuperacionChart from './charts/RecuperacionChart'
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: '#303030',
      overflow: 'auto'
    },
    flags: {
      color: '#26c6da',
      fontSize: 16,
      fontWeight: 'bold',
      fontFamily: 'Arial'
    },
    indicator: {
      backgroundColor: '#26c6da'
    },
    text: {
      color: 'white'
    },
    boldtext: {
      color: 'black',
      fontSize: 17,
      fontWeight: 'bold',
      fontFamily: 'Arial'
    },
    dashboard: {
      flexFlow: "wrap",
      alignItems: "baseline",
      justifyItems: "flex-start"
    },
    tabpanel: {
      marginLeft: "auto",
      marginRight: "auto"
    }
  });


  class DashboardComparacion extends Component{
    constructor(props) {
        super(props)
        
    }


  

    render(){

                if(this.props.area=="Transparencia")
                    return(
                        <Grid container xs={12}>
                            <DonutChart call="/api/dashboard_transparencia" estado={this.props.estado_uno} area={this.props.area} dato="sujetos_obligados_sancionados" id="contractId"/>
                            <DonutChart call="/api/dashboard_transparencia" estado={this.props.estado_dos} area={this.props.area} dato="sujetos_obligados_sancionados" id="contractId2"/>
                            <DonutChart call="/api/dashboard_transparencia" estado={this.props.estado_uno} area={this.props.area} dato="portales_solventados" id="contractId3"/>
                            <DonutChart call="/api/dashboard_transparencia" estado={this.props.estado_dos} area={this.props.area} dato="portales_solventados" id="contractId4"/>
                        </Grid>
                    )
                else if(this.props.area=="Finanzas")
                    return(
                    <Grid container xs={12}>
                        <RecuperacionChart call="/api/dashboard_finanzas" estado={this.props.estado_uno} area={this.props.area} id="contractId"/>
                        <RecuperacionChart call="/api/dashboard_finanzas" estado={this.props.estado_dos} area={this.props.area} id="contractId2"/>
                        
                    </Grid>
                    )
              else if(this.props.area=="PoderJudicial")
                    return(
                    <Grid container xs={12}>
                        <DonutChart call="/api/dashboard_poder_judicial" estado={this.props.estado_uno} area={this.props.area} dato="juicio_servidores_publicos" id="contractId"/>
                        <DonutChart call="/api/dashboard_poder_judicial" estado={this.props.estado_dos} area={this.props.area} dato="juicio_servidores_publicos" id="contractId2"/>
                        <DonutChart call="/api/dashboard_poder_judicial" estado={this.props.estado_uno} area={this.props.area} dato="juicio_particulares" id="contractId"/>
                        <DonutChart call="/api/dashboard_poder_judicial" estado={this.props.estado_dos} area={this.props.area} dato="juicio_particulares" id="contractId4"/>
                        <RecuperacionChart call="/api/dashboard_poder_judicial" estado={this.props.estado_uno} area={this.props.area} dato="recuperacion" id="contractId5"/>
                        <RecuperacionChart call="/api/dashboard_poder_judicial" estado={this.props.estado_dos} area={this.props.area} dato="recuperacion" id="contractId6"/>
                        
                    </Grid>
                    )
                else if(this.props.area=="Tribunal")
                    return(
                    <Grid container xs={12}>
                        <DonutChart call="/api/dashboard_tribunal" estado={this.props.estado_uno} area={this.props.area} dato="investigacion" id="contractId"/>
                        <DonutChart call="/api/dashboard_tribunal" estado={this.props.estado_dos} area={this.props.area} dato="investigacion" id="contractId2"/>
                        <DonutChart call="/api/dashboard_tribunal" estado={this.props.estado_uno} area={this.props.area} dato="servidores_publicos" id="contractId3"/>
                        <DonutChart call="/api/dashboard_tribunal" estado={this.props.estado_dos} area={this.props.area} dato="servidores_publicos" id="contractId4"/>
                        <DonutChart call="/api/dashboard_tribunal" estado={this.props.estado_uno} area={this.props.area} dato="sentencias_emitidas" id="contractId5"/>
                        <DonutChart call="/api/dashboard_tribunal" estado={this.props.estado_dos} area={this.props.area} dato="sentencias_emitidas" id="contractId6"/>
                        <DonutChart call="/api/dashboard_tribunal" estado={this.props.estado_uno} area={this.props.area} dato="sentencias_condenatorias_firmes" id="contractId7"/>
                        <DonutChart call="/api/dashboard_tribunal" estado={this.props.estado_dos} area={this.props.area} dato="sentencias_condenatorias_firmes" id="contractId8"/>
                        
                        <RecuperacionChart call="/api/dashboard_tribunal" estado={this.props.estado_uno} area={this.props.area} dato="recuperacion" id="contractId9"/>
                        <RecuperacionChart call="/api/dashboard_tribunal" estado={this.props.estado_dos} area={this.props.area} dato="recuperacion" id="contractId10"/>
                        
                    </Grid>
                    )
                else if(this.props.area=="Fiscalia")
                    return(<h1>Fiscalia</h1>)
                else
                    return(<h1>DEFAULT</h1>)
        
    }
  }

  export default withStyles(styles)(DashboardComparacion);
