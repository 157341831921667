import React,{useEffect,useState} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import { removeIndicadorSession, getDependencia ,getIndicador} from '../common/Utils';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {enviarDatosCapturados,editarRegistro} from '../common/Api';
import API from '../common/Api';
import TitleCard from '../dashboard/TitleCard';
import './Form.css'

const useStyles = makeStyles((theme) => ({

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

      offset:theme.mixins.toolbar

    }));


const PoderJudicial = (props) => {
    const classes = useStyles();
    const indicador = getIndicador();
    const dependencia = getDependencia();
    var  resultado=""
    if(dependencia!="PoderJudicial"){
      removeIndicadorSession();
    }
    const onSubmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.target);
      var json_data = getData(data);
      var estado = sessionStorage["entidad_federativa"];
      console.log(json_data);
      if(!indicador){
       enviarDatosCapturados("PoderJudicial",json_data,estado,props);
      }else{
       editarRegistro(indicador,"PoderJudicial",estado,json_data,props);
      }
      };

      const  [select_disabled, set_select_disabled] = useState(false);
      const  [field_disabled, set_field_disabled] = useState(false);
      const [indicador_40,set_indicador_40] = useState();
const [total_indicador_40,set_total_indicador_40] = useState();
const [porcentaje_indicador_40,set_porcentaje_indicador_40] = useState();
const [indicador_41,set_indicador_41] = useState();
const [total_indicador_41,set_total_indicador_41] = useState();
const [porcentaje_indicador_41,set_porcentaje_indicador_41] = useState();
const [indicador_42,set_indicador_42] = useState();
const [total_indicador_42,set_total_indicador_42] = useState();
const [porcentaje_indicador_42,set_porcentaje_indicador_42] = useState();
const [indicador_43,set_indicador_43] = useState();
const [total_indicador_43,set_total_indicador_43] = useState();
const [porcentaje_indicador_43,set_porcentaje_indicador_43] = useState();
const [indicador_44,set_indicador_44] = useState();
const [total_indicador_44,set_total_indicador_44] = useState();
const [porcentaje_indicador_44,set_porcentaje_indicador_44] = useState();
const [indicador_45,set_indicador_45] = useState();
const [total_indicador_45,set_total_indicador_45] = useState();
const [porcentaje_indicador_45,set_porcentaje_indicador_45] = useState();
const [indicador_46,set_indicador_46] = useState();
const [total_indicador_46,set_total_indicador_46] = useState();
const [porcentaje_indicador_46,set_porcentaje_indicador_46] = useState();
const [indicador_47,set_indicador_47] = useState();
const [total_indicador_47,set_total_indicador_47] = useState();
const [porcentaje_indicador_47,set_porcentaje_indicador_47] = useState();
const [indicador_48,set_indicador_48] = useState();
const [total_indicador_48,set_total_indicador_48] = useState();
const [porcentaje_indicador_48,set_porcentaje_indicador_48] = useState();
const [indicador_49,set_indicador_49] = useState();
const [total_indicador_49,set_total_indicador_49] = useState();
const [porcentaje_indicador_49,set_porcentaje_indicador_49] = useState();
const [indicador_50,set_indicador_50] = useState();
const [total_indicador_50,set_total_indicador_50] = useState();
const [porcentaje_indicador_50,set_porcentaje_indicador_50] = useState();
const [indicador_51,set_indicador_51] = useState();
const [total_indicador_51,set_total_indicador_51] = useState();
const [porcentaje_indicador_51,set_porcentaje_indicador_51] = useState();
const [indicador_52,set_indicador_52] = useState();
const [total_indicador_52,set_total_indicador_52] = useState();
const [porcentaje_indicador_52,set_porcentaje_indicador_52] = useState();
const [indicador_53,set_indicador_53] = useState();
const [total_indicador_53,set_total_indicador_53] = useState();
const [porcentaje_indicador_53,set_porcentaje_indicador_53] = useState();
const [indicador_54,set_indicador_54] = useState();
const [total_indicador_54,set_total_indicador_54] = useState();
const [porcentaje_indicador_54,set_porcentaje_indicador_54] = useState();
const [indicador_55,set_indicador_55] = useState();
const [total_indicador_55,set_total_indicador_55] = useState();
const [porcentaje_indicador_55,set_porcentaje_indicador_55] = useState();

      const  [year,set_year] = useState();
      const onChange = (e) => {
        set_indicador_40(document.getElementById('indicador_40').value);
        set_total_indicador_40(document.getElementById('total_indicador_40').value);
        resultado = (document.getElementById('indicador_40').value/
        document.getElementById('total_indicador_40').value)*100
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_40(((resultado.toFixed(2))).toString() + " %");
        }else{
          set_porcentaje_indicador_40("0%");
        }

        set_indicador_41(document.getElementById('indicador_41').value);
        set_total_indicador_41(document.getElementById('total_indicador_41').value);
        resultado = document.getElementById('indicador_41').value/
        document.getElementById('total_indicador_41').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_41(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_41("0%");
        }

        set_indicador_42(document.getElementById('indicador_42').value);
        set_total_indicador_42(document.getElementById('total_indicador_42').value);
        resultado = document.getElementById('indicador_42').value/
        document.getElementById('total_indicador_42').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_42(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_42("0%");
        }

        set_indicador_43(document.getElementById('indicador_43').value);
        set_total_indicador_43(document.getElementById('total_indicador_43').value);
        resultado = document.getElementById('indicador_43').value/
        document.getElementById('total_indicador_43').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_43(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_43("0%");
        }

        set_indicador_44(document.getElementById('indicador_44').value);


        set_indicador_45(document.getElementById('indicador_45').value);


        set_indicador_46(document.getElementById('indicador_46').value);
        set_total_indicador_46(document.getElementById('total_indicador_46').value);
        resultado = document.getElementById('indicador_46').value/
        document.getElementById('total_indicador_46').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_46(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_46("0%");
        }

        set_indicador_47(document.getElementById('indicador_47').value);
        set_total_indicador_47(document.getElementById('total_indicador_47').value);
        resultado = document.getElementById('indicador_47').value/
        document.getElementById('total_indicador_47').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_47(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_47("0%");
        }

        set_indicador_48(document.getElementById('indicador_48').value);
        set_total_indicador_48(document.getElementById('total_indicador_48').value);
        resultado = document.getElementById('indicador_48').value/
        document.getElementById('total_indicador_48').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_48(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_48("0%");
        }

        set_indicador_49(document.getElementById('indicador_49').value);
        set_total_indicador_49(document.getElementById('total_indicador_49').value);
        resultado = document.getElementById('indicador_49').value/
        document.getElementById('total_indicador_49').value
        if(!Number.isNaN(resultado)){
          set_porcentaje_indicador_49(((resultado.toFixed(2))*100).toString() + " %");
        }else{
          set_porcentaje_indicador_49("0%");
        }

        set_indicador_50(document.getElementById('indicador_50').value);


        set_indicador_51(document.getElementById('indicador_51').value);


        set_indicador_52(document.getElementById('indicador_52').value);


        set_indicador_53(document.getElementById('indicador_53').value);

        set_indicador_54(document.getElementById('indicador_54').value);


        set_indicador_55(document.getElementById('indicador_55').value);

        calcular();
      };

      useEffect(() =>{
        if(indicador){
          API.get("/api/indicador/"+indicador)
          .then(response => {
                set_select_disabled(true);
                if(response.data.datos.year=="2020" || response.data.datos.year=="2019"){
                  set_field_disabled(true)
                }else{
                  set_field_disabled(false)
                }
                set_year(response.data.datos.year);
                set_total_indicador_40(response.data.datos.sancion.indicador_40.total);
                set_indicador_40(response.data.datos.sancion.indicador_40.numerador);
                set_porcentaje_indicador_40(response.data.datos.sancion.indicador_40.porcentaje);
                set_total_indicador_41(response.data.datos.sancion.indicador_41.total);
                set_indicador_41(response.data.datos.sancion.indicador_41.numerador);
                set_porcentaje_indicador_41(response.data.datos.sancion.indicador_41.porcentaje);
                set_total_indicador_42(response.data.datos.sancion.indicador_42.total);
                set_indicador_42(response.data.datos.sancion.indicador_42.numerador);
                set_porcentaje_indicador_42(response.data.datos.sancion.indicador_42.porcentaje);
                set_total_indicador_43(response.data.datos.sancion.indicador_43.total);
                set_indicador_43(response.data.datos.sancion.indicador_43.numerador);
                set_porcentaje_indicador_43(response.data.datos.sancion.indicador_43.porcentaje);
                set_indicador_44(response.data.datos.sancion.indicador_44);
                set_indicador_45(response.data.datos.sancion.indicador_45);
                set_total_indicador_46(response.data.datos.sancion.indicador_46.total);
                set_indicador_46(response.data.datos.sancion.indicador_46.numerador);
                set_porcentaje_indicador_46(response.data.datos.sancion.indicador_46.porcentaje);
                set_total_indicador_47(response.data.datos.sancion.indicador_47.total);
                set_indicador_47(response.data.datos.sancion.indicador_47.numerador);
                set_porcentaje_indicador_47(response.data.datos.sancion.indicador_47.porcentaje);
                set_total_indicador_48(response.data.datos.sancion.indicador_48.total);
                set_indicador_48(response.data.datos.sancion.indicador_48.numerador);
                set_porcentaje_indicador_48(response.data.datos.sancion.indicador_48.porcentaje);
                set_total_indicador_49(response.data.datos.sancion.indicador_49.total);
                set_indicador_49(response.data.datos.sancion.indicador_49.numerador);
                set_porcentaje_indicador_49(response.data.datos.sancion.indicador_49.porcentaje);
                set_indicador_50(response.data.datos.sancion.indicador_50);
                set_indicador_51(response.data.datos.sancion.indicador_51);
                set_indicador_52(response.data.datos.sancion.indicador_52);
                set_indicador_53(response.data.datos.recuperacion.indicador_53);
                set_indicador_54(response.data.datos.recuperacion.indicador_54);
                set_indicador_55(response.data.datos.recuperacion.indicador_55);

              }).catch(error => {
                    return error;
            });
        }

      },[]);
      const { match } = props;
    return (

      <div className="auth-wrapper">
      <div className="auth-inner">
          <TitleCard title="Poder Judicial(Sanción)" />
          <form
            onSubmit={onSubmit}
             autoComplete="off"
             id="PoderJudicialForm"
             className={classes.form}
           >
             <Grid item xs={1}>
              <FormControl variant="filled" className={classes.formControl}>
                  <Select
                    native

                    value={year}

                    disabled={select_disabled === true ? true : false}
                    inputProps={{
                      name: 'year',
                      id: 'year',
                    }}
                  >
 <option value={year}>{year}</option>
                    <option value="2021">2021</option>
                  <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </Select>
                  </FormControl>
              </Grid>

              <Grid container xs={12} spacing={1}>
              <Grid item xs={12}>

              <div class="settings">


              <label>Total de denuncias recibidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_40}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_40'
                    name="sancion.indicador_40.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />
               <label>Número denuncias que derivan en sanción penal contra un servidor público </label>

             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_40}
             inputStyle ={{width: '50%'}}
             id='indicador_40'
             name="sancion.indicador_40.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

<label>% Denuncias que derivan en sanciones penales a servidores públicos</label>

           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_40'
             name="sancion.indicador_40.porcentaje"
             value={porcentaje_indicador_40}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label> Total de sentencias emitidas por un órgano jurisdiccional por delitos relacionados a hechos de corrupción </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_41}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_41'
                    name="sancion.indicador_41.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de sentencias condenatorias a servidores públicos por hechos de corrupción </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_41}
             inputStyle ={{width: '50%'}}
             id='indicador_41'
             name="sancion.indicador_41.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Sentencias Condenatorias a Servidores Públicos por delitos relacionados a hechos de corrupción</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_41'
             name="sancion.indicador_41.porcentaje"
             value={porcentaje_indicador_41}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de sentencias emitidas por un órgano jurisdiccional por delitos relacionados a hechos de corrupción </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_42}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_42'
                    name="sancion.indicador_42.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de sentencias condenatorias a particulares por hechos de corrupción </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_42}
             inputStyle ={{width: '50%'}}
             id='indicador_42'
             name="sancion.indicador_42.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Sentencias Condenatorias a Particulares por delitos relacionados a hechos de corrupción</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_42'
             name="sancion.indicador_42.porcentaje"
             value={porcentaje_indicador_42}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de quejas y denuncias recibidas</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_43}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_43'
                    name="sancion.indicador_43.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Número de quejas y denuncias que derivan en sanción a penal contra un servidor público </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_43}
             inputStyle ={{width: '50%'}}
             id='indicador_43'
             name="sancion.indicador_43.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% Quejas y denuncias que derivan en sanciones penales a servidores públicos</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_43'
             name="sancion.indicador_43.porcentaje"
             value={porcentaje_indicador_43}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />




             <label>Número de servidores con sanciones penales  </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_44}
             inputStyle ={{width: '50%'}}
             id='indicador_44'
             name="sancion.indicador_44"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />




             <label>Número de particulares con sanciones penales  </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_45}
             inputStyle ={{width: '50%'}}
             id='indicador_45'
             name="sancion.indicador_45"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />





                <label>total de juicios donde un particular es parte acusada</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_46}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_46'
                    name="sancion.indicador_46.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Particulares sancionados </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_46}
             inputStyle ={{width: '50%'}}
             id='indicador_46'
             name="sancion.indicador_46.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Particulares sancionados  </label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_46'
             name="sancion.indicador_46.porcentaje"
             value={porcentaje_indicador_46}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de juicios donde un servidor público es parte acusada</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_47}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_47'
                    name="sancion.indicador_47.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Servidores públicos sancionados</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_47}
             inputStyle ={{width: '50%'}}
             id='indicador_47'
             name="sancion.indicador_47.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De servidores públicos sancionados  </label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_47'
             name="sancion.indicador_47.porcentaje"
             value={porcentaje_indicador_47}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de Sentencias Emitidas </label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_48}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_48'
                    name="sancion.indicador_48.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Sentencias Condenatorias Obtenidas </label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_48}
             inputStyle ={{width: '50%'}}
             id='indicador_48'
             name="sancion.indicador_48.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Sentencias Condenatorias Obtenidas</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_48'
             name="sancion.indicador_48.porcentaje"
             value={porcentaje_indicador_48}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />



                <label>Total de Sentencias condenatorias</label>
               <TextField
                    variant="outlined"
                    margin="normal"
                    color='primary'
                    value={total_indicador_49}
                    inputStyle ={{width: '50%'}}
                    id='total_indicador_49'
                    name="sancion.indicador_49.total"
                    onChange={onChange} disabled={field_disabled === true ? true : false}
              />

             <label>Sentencias condenatorias firmes</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_49}
             inputStyle ={{width: '50%'}}
             id='indicador_49'
             name="sancion.indicador_49.numerador"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

           <label>% De Sentencias Condenatorias Firmes</label>
           <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             inputStyle ={{width: '50%'}}
             disabled
             id='porcentaje_indicador_49'
             name="sancion.indicador_49.porcentaje"
             value={porcentaje_indicador_49}
             onChange={onChange} disabled={field_disabled === true ? true : false}
             inputProps={
               { readOnly: true, }
             }

           />

             <label>Número de particulares y funcionarios con sanción firme</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_50}
             inputStyle ={{width: '50%'}}
             id='indicador_50'
             name="sancion.indicador_50"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />







             <label>Número de Procedimientos de Responsabilidades que se encuentran en impugnación</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_51}
             inputStyle ={{width: '50%'}}
             id='indicador_51'
             name="sancion.indicador_51"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

                <label>Número de impugnaciones (recursos, juicios, amparos) otorgadas en contra de las sanciones</label>
             <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_52}
             inputStyle ={{width: '50%'}}
             id='indicador_52'
             name="sancion.indicador_52"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />






              </div>



              </Grid>
              <Grid item xs={6}>

              </Grid>


              </Grid>

              <TitleCard title="Poder Judicial(Recuperación)" />
              <span>Ingresos a la hacienda pública </span><br/>
              <Grid container xs={12} spacing={1}>

              <div class="settings">
                    <label>
                   Por cobro de sanciones económicas o indemnizaciones por faltas administrativas o hechos de corrupción
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_53}
             inputStyle ={{width: '50%'}}
             id='indicador_53'
             name="recuperacion.indicador_53"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
          <label>
           Montos recuperados por decomiso de bienes producto de hechos ilícitos
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_54}
             inputStyle ={{width: '50%'}}
             id='indicador_54'
             name="recuperacion.indicador_54"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />

                    <label>
                    Montos recuperados por extinción de dominio
              </label>
              <TextField
             variant="outlined"
             margin="normal"
             color='primary'
             value={indicador_55}
             inputStyle ={{width: '50%'}}
             id='indicador_55'
             name="recuperacion.indicador_55"
             onChange={onChange} disabled={field_disabled === true ? true : false}
           />
           </div>
              </Grid>
              <Button
             type="submit"
             fullWidth
             variant="contained"
             color="primary"
             size="large"
             disabled={field_disabled === true ? true : false}
             // className={classes.submit}
           >
             Enviar
           </Button>

                   </form>
        </div>
        </div>
          );
}

function calcular(){

  if(document.getElementById("indicador_40").value > 0
  && document.getElementById("total_indicador_40").value > 0){
  var resultado = document.getElementById("indicador_40").value/
                document.getElementById("total_indicador_40").value
  document.getElementById("porcentaje_indicador_40").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_41").value > 0
  && document.getElementById("total_indicador_41").value > 0){
  var resultado = document.getElementById("indicador_41").value/
                document.getElementById("total_indicador_41").value
  document.getElementById("porcentaje_indicador_41").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_42").value > 0
  && document.getElementById("total_indicador_42").value > 0){
  var resultado = document.getElementById("indicador_42").value/
                document.getElementById("total_indicador_42").value
  document.getElementById("porcentaje_indicador_42").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_43").value > 0
  && document.getElementById("total_indicador_43").value > 0){
  var resultado = document.getElementById("indicador_43").value/
                document.getElementById("total_indicador_43").value
  document.getElementById("porcentaje_indicador_43").value = ((resultado.toFixed(2))*100).toString() + " %";
  }




if(document.getElementById("indicador_46").value > 0
  && document.getElementById("total_indicador_46").value > 0){
  var resultado = document.getElementById("indicador_46").value/
                document.getElementById("total_indicador_46").value
  document.getElementById("porcentaje_indicador_46").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_47").value > 0
  && document.getElementById("total_indicador_47").value > 0){
  var resultado = document.getElementById("indicador_47").value/
                document.getElementById("total_indicador_47").value
  document.getElementById("porcentaje_indicador_47").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_48").value > 0
  && document.getElementById("total_indicador_48").value > 0){
  var resultado = document.getElementById("indicador_48").value/
                document.getElementById("total_indicador_48").value
  document.getElementById("porcentaje_indicador_48").value = ((resultado.toFixed(2))*100).toString() + " %";
  }


if(document.getElementById("indicador_49").value > 0
  && document.getElementById("total_indicador_49").value > 0){
  var resultado = document.getElementById("indicador_49").value/
                document.getElementById("total_indicador_49").value
  document.getElementById("porcentaje_indicador_49").value = ((resultado.toFixed(2))*100).toString() + " %";
  }




}

function getData(data) {
  var data = {};

  var form = document.getElementById("PoderJudicialForm");
  var allInputs = form.getElementsByTagName('input');
  for (let i = 0; i < allInputs.length; i++) {
    var str = allInputs[i].name;
    var val = allInputs[i].value;
    var strArr = str.split('.');
    generateObj(data, strArr, val);

    function generateObj(obj, arr, val) {
      //alert(val);
      if (arr.length === 1) {
        if(val==""){
          val="NR";
        }
        obj[arr[0]] = val
        return;
      }

      var restArr = arr.splice(1);
      if (!obj[arr[0]]) {
        obj[arr[0]] = {};
      }
      generateObj(obj[arr[0]], restArr, val);
    }

  };
  data["year"] = document.getElementById("year").value
  //alert(data);
  return data;
}

export default PoderJudicial;